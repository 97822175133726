import React, { useMemo } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

export interface Rows {
  issuingAgency?: boolean
  respondent?: boolean
  respondentAddress?: boolean
  infractionCode?: boolean
  standardDescription?: boolean
  hiredBy?: boolean
}

type RowProps<K extends keyof ViolationDetails> = Pick<ViolationDetails, K>

const IssuingAgency: React.FC<RowProps<'issuingAgency'>> = ({
  issuingAgency,
}) => (
  <tr className={styles['param-row']}>
    <td className={styles['param-title-cell']}>
      <p className={styles['param-title']}>{'Issuing Agency'}</p>
    </td>
    <td className={styles['param-value-cell']}>
      <p className={clsx(styles['param-value'], styles['uppercase'])}>
        {issuingAgency}
      </p>
    </td>
  </tr>
)

const Respondent: React.FC<RowProps<'respondent'>> = ({ respondent }) => (
  <tr className={styles['param-row']}>
    <td className={styles['param-title-cell']}>
      <p className={styles['param-title']}>{'Respondent'}</p>
    </td>
    <td className={styles['param-value-cell']}>
      <p
        className={clsx(
          styles['param-value'],
          styles['blue'],
          styles['capitalize']
        )}
      >
        {respondent?.toLowerCase()}
      </p>
    </td>
  </tr>
)

const RespondentAddress: React.FC<RowProps<'respondentAddress'>> = ({
  respondentAddress,
}) => (
  <tr className={styles['param-row']}>
    <td className={styles['param-title-cell']}>
      <p className={styles['param-title']}>{'Respondent Address'}</p>
    </td>
    <td className={styles['param-value-cell']}>
      <p className={clsx(styles['param-value'], styles['uppercase'])}>
        {respondentAddress}
      </p>
    </td>
  </tr>
)

const HiredBy: React.FC<RowProps<'user'>> = ({ user }) => (
  <tr className={styles['param-row']}>
    <td className={styles['param-title-cell']}>
      <p className={styles['param-title']}>{'Hired By'}</p>
    </td>
    <td className={styles['param-value-cell']}>
      <p className={clsx(styles['param-value'], styles['uppercase'])}>
        {`${user?.firstName} ${user?.lastName}`}
      </p>
    </td>
  </tr>
)

const InfractionCode: React.FC<RowProps<'infractionCode'>> = ({
  infractionCode,
}) => {
  const code = useMemo(
    () =>
      infractionCode && (
        <p className={clsx(styles['param-value'], styles['code'])}>
          {infractionCode}
        </p>
      ),
    [infractionCode]
  )

  return (
    <tr className={styles['param-row']}>
      <td className={styles['param-title-cell']}>
        <p className={styles['param-title']}>{'Infraction Code'}</p>
      </td>
      <td className={styles['param-value-cell']}>{code}</td>
    </tr>
  )
}

const StandartDescription: React.FC<RowProps<'standartDescription'>> = ({
  standartDescription,
}) => (
  <tr className={styles['param-row']}>
    <td className={styles['param-title-cell']}>
      <p className={styles['param-title']}>{'Standard Description'}</p>
    </td>
    <td className={styles['param-value-cell']}>
      <p className={clsx(styles['param-value'], styles['uppercase'])}>
        {standartDescription}
      </p>
    </td>
  </tr>
)

const getRows = (
  rows: Rows,
  {
    issuingAgency,
    respondent,
    respondentAddress,
    user,
    infractionCode,
    standartDescription,
  }: ViolationDetails
) => {
  const nodes = []
  rows.issuingAgency &&
    nodes.push(
      <IssuingAgency
        issuingAgency={issuingAgency}
        key={`issuingAgency-${issuingAgency}`}
      />
    )
  rows.respondent &&
    nodes.push(
      <Respondent respondent={respondent} key={`respondent-${respondent}`} />
    )
  rows.respondentAddress &&
    nodes.push(
      <RespondentAddress
        respondentAddress={respondentAddress}
        key={`respondentAddress-${respondentAddress}`}
      />
    )
  rows.hiredBy &&
    user &&
    nodes.push(<HiredBy user={user} key={`hiredUser-${user.id}`} />)
  rows.infractionCode &&
    nodes.push(
      <InfractionCode
        infractionCode={infractionCode}
        key={`infractionCode-${infractionCode}`}
      />
    )
  rows.standardDescription &&
    nodes.push(
      <StandartDescription
        standartDescription={standartDescription}
        key={`standartDescription-${standartDescription}`}
      />
    )
  return nodes
}

export default getRows
