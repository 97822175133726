enum PaidStatusType {
  unpaidFee = 'unpaid-fee',
  unpaid = 'unpaid',
  paid = 'paid',
}

const PaidStatusTypeLabel = {
  [PaidStatusType.unpaidFee]: 'unpaid',
  [PaidStatusType.unpaid]: 'unpaid',
  [PaidStatusType.paid]: 'paid',
}

const paidStatusTypes = [
  PaidStatusType.unpaidFee,
  PaidStatusType.unpaid,
  PaidStatusType.paid,
]

export { PaidStatusTypeLabel, paidStatusTypes }

export default PaidStatusType
