import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import ViolationsTableWrapper from '../ViolationsTableWrapper'
import ViolationsWrapper from '../wrapper'

import { ViolationType } from '@typings/enums'

const HiredViolations: React.FC<RouteComponentProps> = ViolationsWrapper(
  ViolationsTableWrapper<AttendedViolation>(ViolationType.attended)
)

export default HiredViolations
