import React from 'react'
import { ReactSVG } from 'react-svg'
import clsx from 'clsx'

import Tooltip from '@components/Tooltip'

import InfoIcon from '@assets/info.svg'

import styles from './styles.module.scss'

interface Props {
  children?: React.ReactNode
  className?: string
  tooltipClassName?: string
}

const InfoTooltip: React.FC<Props> = ({
  children,
  className,
  tooltipClassName,
}) => (
  <Tooltip
    tooltip={children}
    className={clsx(styles['tooltip'], className)}
    tooltipClassName={clsx(styles['tooltip-text'], tooltipClassName)}
  >
    <ReactSVG src={InfoIcon} className={clsx('svg', styles['info-tooltip'])} />
  </Tooltip>
)

export default InfoTooltip
