import React, { useMemo } from 'react'
import clsx from 'clsx'

import styles from '../styles.module.scss'
import Notes from './Notes'

interface Props {
  activity: ViolationActivity[]
  notes?: string[] | null
  sendNote: (notes: string[], cb?: () => void) => void
}

const Activity: React.FC<Props> = ({ activity, notes, sendNote }) => {
  const activities = useMemo(
    () =>
      activity.map(({ date, activity }, index) => (
        <li key={`${date}-${index}`} className={styles['activity-item']}>
          <p className={styles['activity-date']}>
            <span className={styles['month']}>{date?.slice(0, 3)}</span>{' '}
            <span className={styles['day']}>{date?.slice(3)}</span>
          </p>
          <div
            className={styles['activity-text']}
            dangerouslySetInnerHTML={{ __html: activity }}
          />
        </li>
      )),
    [activity]
  )

  return (
    <section className={clsx(styles['section'], styles['activity'])}>
      <div className={styles['activity-container']}>
        <h2 className={styles['title']}>{'Activity'}</h2>
        <div className={styles['activities-container']}>
          <ul className={styles['activities']}>
            {activities}
            <li className={styles['activity-item']}>
              <p className={styles['no-activity']}>{'no previous activity'}</p>
            </li>
          </ul>
        </div>
      </div>
      <Notes notes={notes} sendNote={sendNote} />
    </section>
  )
}

export default Activity
