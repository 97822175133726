enum CaseType {
  openContainerOfAlcohol = 'Open Container of Alcohol',
  receptacleViolation = 'Receptacle Violation',
  looseRubbish = 'Loose Rubbish',
  dirtySidewalkArea = 'Dirty Sidewalk/Area',
  recycling = 'Recycling',
  improperDisposalOfBedding = 'Improper Disposal of Bedding',
  snowAndIceRemoval = 'Snow and Ice Removal',
  rodentAndPestControl = 'Rodent and Pest Control',
  standingWater = 'Standing Water',
  emissionsIdling = 'Emissions/Idling',
  abandonedVehicle = 'Abandoned Vehicle',
  littering = 'Littering',
  publicUrination = 'Public Urination',
  sidewalkObstruction = 'Sidewalk Obstruction',
  restaurantCases = 'Restaurant Cases',
  commercialBike = 'Commercial Bike (DOT summons)',
  unleashedDog = 'Unleashed Dog',
  failureToRemoveCanineWaste = 'Failure to Remove Canine Waste',
  spitting = 'Spitting',
  throwOutOrSweepOut = 'Throw Out/Sweep Out',
  beingInParkAfter = 'Being in Park After',
  unreasonableorExcessiveNoise = 'Unreasonable or Excessive Noise',
  healthDepartmentSummons = 'Health Department Summons',
  noPrivateCarterSign = 'No Private Carter Sign',
  vendingOrMobilFoodVending = 'Vending/Mobil Food Vending',
  parksDepartmentSummons = 'Parks Department Summons',
  DOBWorkWithoutPermit = 'DOB- Work without a Permit',
  DOBOccupancyContrary = 'DOB Occupancy Contrary',
  DOBElectricalCodeSummons = 'DOB Electrical Code summons',
  DOBZoning = 'DOB Zoning',
}

const caseTypes = [
  CaseType.openContainerOfAlcohol,
  CaseType.receptacleViolation,
  CaseType.looseRubbish,
  CaseType.dirtySidewalkArea,
  CaseType.recycling,
  CaseType.improperDisposalOfBedding,
  CaseType.snowAndIceRemoval,
  CaseType.rodentAndPestControl,
  CaseType.standingWater,
  CaseType.emissionsIdling,
  CaseType.abandonedVehicle,
  CaseType.littering,
  CaseType.publicUrination,
  CaseType.sidewalkObstruction,
  CaseType.restaurantCases,
  CaseType.commercialBike,
  CaseType.unleashedDog,
  CaseType.failureToRemoveCanineWaste,
  CaseType.spitting,
  CaseType.throwOutOrSweepOut,
  CaseType.beingInParkAfter,
  CaseType.unreasonableorExcessiveNoise,
  CaseType.healthDepartmentSummons,
  CaseType.noPrivateCarterSign,
  CaseType.vendingOrMobilFoodVending,
  CaseType.parksDepartmentSummons,
  CaseType.DOBWorkWithoutPermit,
  CaseType.DOBOccupancyContrary,
  CaseType.DOBElectricalCodeSummons,
  CaseType.DOBZoning,
]

export { caseTypes }

export default CaseType
