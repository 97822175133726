import React, { useCallback, useEffect, useRef } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import IncomeChart from '@common/IncomeChart'
import HalfYearIncomeChart from '@common/HalfYearIncomeChart'

import paymentsActions from '@redux/payments/actions'

import { IncomePeriodType, emptyHalfYearStats } from '@typings/enums'

import styles from '../styles.module.scss'

const mapStateToProps = (state: ReduxState) => ({
  incomeStats: state.payments.incomeStats,
  lastHalfYearStats: state.payments.lastHalfYearStats,
  userID: state.users.user?.id,
})

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  paymentsActions: bindActionCreators<
    typeof paymentsActions,
    BindedAsyncActions<typeof paymentsActions>
  >(paymentsActions, dispatch),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type NativeProps = {}

type Props = NativeProps & ConnectedProps<typeof connector>

const Charts: React.FC<Props> = ({
  incomeStats = {},
  lastHalfYearStats = emptyHalfYearStats,
  userID,
  paymentsActions: { loadIncomeStats, loadHalfYearIncomeStats },
}) => {
  const periodRef = useRef(IncomePeriodType.daily)

  useEffect(() => {
    userID && loadIncomeStats({ period: periodRef.current, user_id: userID })
  }, [loadIncomeStats, userID])

  const onPeriodChange = useCallback(
    (period: IncomePeriodType) => {
      periodRef.current = period
      loadIncomeStats({ period, user_id: userID })
    },
    [periodRef, userID]
  )

  useEffect(() => {
    userID && loadHalfYearIncomeStats({ user_id: userID })
  }, [loadHalfYearIncomeStats, userID])

  return (
    <section className={styles['charts']}>
      <IncomeChart
        incomeStats={incomeStats}
        initialPeriod={IncomePeriodType.daily}
        onPeriodChange={onPeriodChange}
      />
      <HalfYearIncomeChart halfYearStats={lastHalfYearStats} variant="white" />
    </section>
  )
}

export default connector(Charts) as React.FC<NativeProps>
