const defaultRegexCreator = (name: string) =>
  new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')

export const getQueryParam = (
  name: string,
  url: string,
  regexCreator: (name: string) => RegExp = defaultRegexCreator
) => {
  name = name.replace(/[[\]]/g, '\\$&')

  const regex = regexCreator(name)
  const results = regex.exec(url)

  if (!results) return null

  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export default {
  getQueryParam,
}
