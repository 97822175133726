import { State } from '@typings/enums'

export const address = '1115 Broadway'
export const apartmentNumber = '10th Floor'
export const city = 'New York'
export const state = State.NewYork
export const zipCode = '10010'
export const phone = '212-571-3003'
export const email = 'Info@City5consulting.com'

const city5 = {
  address,
  apartmentNumber,
  city,
  state,
  zipCode,
  phone,
  email,
}

export default city5
