import React from 'react'
import clsx from 'clsx'

import CaseStatus from '@components/CaseStatus'
import ViolationInfoTable from '@common/ViolationInfoTable'

import { formatDate } from '@services/date'

import styles from '../styles.module.scss'

interface Props {
  violation: ViolationDetails
}

const MainInfo: React.FC<Props> = ({ violation }) => {
  const { offenseDate, hearingDate, caseStatus, hearingStatus } = violation
  return (
    <section className={clsx(styles['section'], styles['main-info'])}>
      <ul className={styles['params-list']}>
        <li className={styles['params-list-item']}>
          <div className={styles['params-container']}>
            <p className={styles['param-title']}>{'Offence'}</p>
            <p className={styles['param-value']}>{formatDate(offenseDate)}</p>
          </div>
        </li>
        <li className={styles['params-list-item']}>
          <div className={styles['params-container']}>
            <p className={styles['param-title']}>{'Hearing Date'}</p>
            <p className={styles['param-value']}>{formatDate(hearingDate)}</p>
          </div>
        </li>
        <li className={styles['params-list-item']}>
          <div className={styles['params-container']}>
            <p className={styles['param-title']}>{'Case Status'}</p>
            <CaseStatus type={caseStatus} className={styles['param-value']} />
          </div>
        </li>
        <li className={styles['params-list-item']}>
          <div className={styles['params-container']}>
            <p className={styles['param-title']}>{'Hearing Status'}</p>
            <p className={styles['param-value']}>{hearingStatus}</p>
          </div>
        </li>
      </ul>
      <ViolationInfoTable
        violation={violation}
        className={styles['params-table']}
      />
    </section>
  )
}

export default MainInfo
