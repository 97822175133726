import React, { useMemo } from 'react'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { ReactSVG } from 'react-svg'

import Input from '@components/Input'
import Link from '@components/Link'

import links from '@routes/links'

import CheckmarkIcon from '@assets/checkmark.svg'

import styles from './styles.module.scss'

export interface FormData {
  email: string
  password: string
  remember: boolean
}

interface Props {
  isLoading: boolean
  onSubmit: (values: FormData) => void
  error: string
  resetError: () => void
}

const Form: React.FC<Props> = ({ isLoading, onSubmit, error, resetError }) => {
  const formik = useFormik<FormData>({
    initialValues: {
      email: '',
      password: '',
      remember: false,
    },
    onSubmit,
  })

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event)
    resetError()
  }

  const checkmark = useMemo(
    () => (
      <ReactSVG
        src={CheckmarkIcon}
        className={clsx('svg', styles['checkmark'])}
      />
    ),
    []
  )

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        name="email"
        id="email"
        type="email"
        placeholder="example@email.com"
        value={formik.values.email}
        onChange={onChange}
        required={true}
        label="Email"
        containerClassName={styles['input-container']}
        error={!!error}
      />
      <Input
        name="password"
        id="password"
        type="password"
        placeholder="•••••••••••••"
        value={formik.values.password}
        onChange={onChange}
        required={true}
        label="Password"
        containerClassName={styles['input-container']}
        error={!!error}
      />
      <div className={styles['form-footer']}>
        <Input
          type="checkbox"
          label="Remember me"
          name="remember"
          checked={formik.values.remember}
          onChange={formik.handleChange}
          checkmark={checkmark}
          containerClassName={styles['checkbox-container']}
          checkmarkContainerClassName={styles['checkmark-container']}
        />
        <Link to={links.forgotPassword()} className={clsx('text', 'blue-link')}>
          {'Forgot Password?'}
        </Link>
      </div>
      <button
        type="submit"
        className={clsx('primary-button', styles['submit-btn'])}
        disabled={isLoading || !formik.isValid}
      >
        {'Login'}
      </button>
    </form>
  )
}

export default Form
