import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'

import InputBaseProps from '@components/Input/BaseProps'
import DefaultFile from './DefaultFile'

import useStateProp from '@hooks/useStateProp'
import { first, toArray } from '@services/utils/array'

import styles from './styles.module.scss'
import { length } from '@services/utils'

export type Props = InputBaseProps<
  'file',
  {
    file?: File | File[]
    onChangeFile?: (file: File | File[] | undefined) => void
    error?: React.ReactNode
    errorClassName?: string
    disableError?: boolean
  }
>

const FileInput: React.FC<Props> = ({
  file: fileProp,
  onChangeFile,
  error,
  errorClassName,
  disableError,
  max,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const selectedInput = useRef(-1)

  const [file, setFile] = useStateProp<File | undefined | File[]>(fileProp)
  const [emptyLast, setEmptyLast] = useState(!file)

  useEffect(() => {
    if (!file || (Array.isArray(file) && file.length === 0)) {
      setEmptyLast(true)
    }
  }, [file, setEmptyLast, selectedInput])

  useEffect(() => {
    onChangeFile?.(file)
  }, [file, onChangeFile])

  const removeFile = useCallback(
    (file: File) => {
      setFile((prev) => {
        if (prev === file) {
          return undefined
        } else if (Array.isArray(prev)) {
          const index = prev.findIndex((prevFile) => prevFile === file)
          const newFile = [...prev]
          newFile.splice(index, 1)
          return newFile
        }
      })
    },
    [setFile, setEmptyLast]
  )

  const updateFile = useCallback(
    (newFile: File | File[], index = -1) => {
      setFile((file) => {
        if (!props.multiple) {
          return first(newFile)
        } else {
          const fileArr = [...toArray(file)]
          if (index < 0 || index > toArray(file).length) {
            return [...fileArr, ...toArray(newFile)]
          } else {
            toArray(newFile).forEach(
              (file, fileIndex) => (fileArr[index + fileIndex] = file)
            )
            return fileArr
          }
        }
      })
    },
    [props.multiple]
  )

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        updateFile(Array.from(e.target.files), selectedInput.current)

        if (selectedInput.current < 0) {
          setEmptyLast(false)
        }
        selectedInput.current = -1
      }
    },
    [selectedInput, updateFile, setEmptyLast]
  )

  const openFile = useCallback(
    (index = -1) => () => {
      selectedInput.current = index
      inputRef.current?.click()
    },
    [inputRef.current, selectedInput]
  )

  const dropFiles = useCallback(
    (newFile: File | File[]) => {
      selectedInput.current = -1
      if (
        Array.isArray(newFile) &&
        max &&
        Number(max) > 0 &&
        Number(max) - length(file) < newFile.length
      ) {
        newFile = newFile.slice(0, Number(max) - length(file))
      }
      updateFile(newFile)
      setEmptyLast(false)
    },
    [setEmptyLast, updateFile, selectedInput, file, max]
  )

  const fileNodes = useMemo(
    () =>
      toArray(file).map((file, index) => (
        <DefaultFile
          key={`${file.name}-${index}`}
          file={file}
          openFile={openFile(index)}
          removeFile={removeFile}
        />
      )),
    [file, removeFile, openFile]
  )

  const emptyFileNode = useMemo(
    () =>
      emptyLast ? (
        <DefaultFile
          file={undefined}
          openFile={openFile()}
          updateFiles={dropFiles}
        />
      ) : null,
    [emptyLast]
  )

  const addLastEmpty = useMemo(() => {
    if (
      !props.multiple ||
      (Array.isArray(file) && max && max > 0 && file.length >= max)
    ) {
      return null
    }
    const add = () => {
      setEmptyLast(true)
      selectedInput.current = -1
      inputRef.current?.click()
    }
    return (
      <button
        onClick={add}
        type="button"
        className={clsx(
          styles['add-empty-file'],
          emptyLast && styles['add-empty-file-hidden']
        )}
      >
        <span className="plus" />
      </button>
    )
  }, [
    emptyLast,
    props.multiple,
    setEmptyLast,
    selectedInput,
    inputRef.current,
    file,
    max,
  ])

  const errorNode = useMemo(() => {
    if (disableError) {
      return null
    }
    if (error === null) {
      error = undefined
    }

    const errorType = typeof error
    switch (errorType) {
      case 'string':
      case 'number': {
        return <p className={clsx('error-text', errorClassName)}>{error}</p>
      }
      case 'undefined':
      case 'boolean': {
        return <div className="error-placeholder" />
      }
      default: {
        return error
      }
    }
  }, [error, errorClassName, disableError])

  return (
    <div className={styles['files-container']}>
      <input
        {...props}
        ref={inputRef}
        onChange={onInputChange}
        multiple={false}
        className={clsx(styles['input'], props.className)}
      />
      <div className={styles['files']}>
        {fileNodes}
        {emptyFileNode}
        {addLastEmpty}
      </div>
      {errorNode}
    </div>
  )
}

export default FileInput
