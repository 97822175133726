export const formatChartValueAxis = (value?: number) =>
  value === undefined || Number.isNaN(value)
    ? ''
    : value < 1000
    ? value
    : `${Number((value / 1000).toFixed(2))}k`

export default {
  formatChartValueAxis,
}
