import { Middleware } from 'redux'

import paymentMiddlewares from './payments'
import violationMiddlewares from './violations'

const reduxMiddlewares: Middleware[] = [
  ...violationMiddlewares,
  ...paymentMiddlewares,
]

export default reduxMiddlewares
