import React, { useMemo, useRef } from 'react'
import { Mask } from 'text-mask-core'

import DefaultInput, { Props as InputProps } from '../DefaultInput'

import useMaskedInput from '@hooks/useMaskedInput'
import { mergeRefs } from '@services'

export interface Props extends InputProps {
  mask: Mask
}

const MaskedInput = (
  { mask, ...props }: Props,
  ref: React.Ref<HTMLInputElement>
) => {
  const localRef = useRef<HTMLInputElement>(null)

  const onChange = useMaskedInput({
    input: localRef,
    mask,
    onChange: props.onChange,
    value: props.value as string,
  })

  const combRef = useMemo(() => mergeRefs(localRef, ref), [ref, localRef])

  return (
    <DefaultInput
      {...props}
      onChange={onChange}
      type={props.type}
      ref={combRef}
    />
  )
}

export default React.forwardRef(MaskedInput)
