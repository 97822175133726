import React, { useMemo } from 'react'

import Checkbox, { Props as CheckboxProps } from '@components/Checkbox'
import DefaultInput, { Props as InputProps } from './DefaultInput'
import MaskedInput from './MaskedInput'
import FileInput, { Props as FileInputProps } from '@components/FileInput'

type Props = CheckboxProps | InputProps | FileInputProps

const Input = (props: Props, ref: React.Ref<HTMLInputElement>) => {
  const Component = useMemo(() => {
    switch (props.type) {
      case 'checkbox':
      case 'radio': {
        return Checkbox
      }
      case 'file': {
        return FileInput
      }
      default: {
        if (props.mask) {
          return MaskedInput
        } else {
          return DefaultInput
        }
      }
    }
  }, [props.type, (props as InputProps).mask]) as React.FC<
    React.PropsWithRef<Props>
  >
  return <Component {...props} ref={ref} />
}

export default React.forwardRef(Input) as React.FC<React.PropsWithRef<Props>>
