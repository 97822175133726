import ActionType from '@redux/payments/types'

const preprocessStatuses: ReduxThunkMiddleware = (_) => (next) => (
  action: AnyAction
) => {
  if (action.type === ActionType.LOAD_STATUSES_SUCCESS) {
    action.payload = action.payload.filter(Boolean)
  }
  return next(action)
}

const paymentMiddlewares = [preprocessStatuses]

export default paymentMiddlewares
