import axios from 'axios'

import { apiUrl, loadStaticsInterval } from '@config'

const api = axios.create({
  baseURL: apiUrl,
})

export const haveToLoadStatics = (timestamp: number) =>
  new Date().getTime() - timestamp > loadStaticsInterval

export default api
