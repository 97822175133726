import React, { useEffect } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import usersActions from '@redux/users/actions'
import links from '@routes/links'
import UserHeadInfo from '@common/UserHeadInfo'
import { isId } from '@services/utils'

const mapStateToProps = (state: ReduxState) => ({
  user: state.users.user,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  usersActions: bindActionCreators<
    typeof usersActions,
    BindedAsyncActions<typeof usersActions>
  >(usersActions, dispatch),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

interface NativeProps {
  id: string
  replace: (path: string) => void
  goBack: () => void
}

type Props = NativeProps & ConnectedProps<typeof connector>

const Head: React.FC<Props> = ({
  id,
  replace,
  goBack,
  user,
  usersActions: { loadDetails },
}) => {
  useEffect(() => {
    if (isId(id)) {
      loadDetails(Number(id))
    } else {
      replace(links.home())
    }
  }, [id, replace, loadDetails])

  return (
    <UserHeadInfo
      firstName={user?.firstName}
      lastName={user?.lastName}
      email={user?.email}
      phone={user?.phone || ''}
      goBack={goBack}
    />
  )
}

export default connector(Head) as React.FC<NativeProps>
