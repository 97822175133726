import React from 'react'
import clsx from 'clsx'

import ViolationBaseInfo from '@common/ViolationBaseInfo'

import styles from '../styles.module.scss'

interface Props {
  violation: ViolationDetails
}

const BaseInfo: React.FC<Props> = ({ violation }) => (
  <ViolationBaseInfo
    violation={violation}
    className={clsx(styles['section'], styles['base-info'])}
  />
)

export default BaseInfo
