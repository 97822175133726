import React from 'react'
import clsx from 'clsx'

import {
  CaseStatusClassName,
  CaseStatusLabel,
  CaseStatusType,
} from '@typings/enums'

import styles from './styles.module.scss'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  type: CaseStatusType
  children?: string
}

const CaseStatus: React.FC<Props> = ({
  type,
  children = CaseStatusLabel[type] || type,
  className,
  ...props
}) => (
  <p
    className={clsx(
      styles['case-status'],
      styles[CaseStatusClassName[type]],
      className
    )}
    title={type}
    {...props}
  >
    {children}
  </p>
)

export default CaseStatus
