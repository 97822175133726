import React, { useMemo } from 'react'
import clsx from 'clsx'

import defaultRenderItem from './item'
import { RenderItemArg, TabNavItem } from './types'

import styles from './styles.module.scss'

interface Props<T extends string> {
  tabs: TabNavItem<T>[]
  value: T
  setValue: (value: T) => void
  onChange?: (value: T) => void
  renderItem?: (arg: RenderItemArg<T>) => React.ReactNode
  className?: string
}

const TabNav = <T extends string>({
  tabs = [],
  value,
  setValue,
  renderItem = defaultRenderItem,
  className,
}: Props<T>) => {
  const activeIndex = useMemo(
    () => (value ? tabs.findIndex((tab) => tab.value === value) : -1),
    [tabs, value]
  )

  const tabsNodes = useMemo(
    () =>
      tabs.map((item, index, self) => (
        <React.Fragment key={item.value}>
          {renderItem({
            item,
            index,
            self,
            activeValue: value,
            activeIndex,
            setValue,
          })}
        </React.Fragment>
      )),
    [tabs, activeIndex, value]
  )

  return <nav className={clsx(styles['tabs'], className)}>{tabsNodes}</nav>
}

export default TabNav
