import React from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

interface Props {
  children?: React.ReactNode
  tooltip?: React.ReactNode
  className?: string
  tooltipClassName?: string
}

const Tooltip = (
  { children, tooltip, className, tooltipClassName }: Props,
  ref: React.Ref<HTMLDivElement>
) => {
  return (
    <div className={clsx(styles['tooltip-container'], className)} ref={ref}>
      {children}
      <div className={clsx(styles['tooltip'], tooltipClassName)}>{tooltip}</div>
    </div>
  )
}

export default React.forwardRef(Tooltip) as React.FC<React.PropsWithRef<Props>>
