import React, { useMemo } from 'react'
import clsx from 'clsx'

import getRows, { Rows } from './rows'

import styles from './styles.module.scss'

interface Props {
  violation: ViolationDetails
  rows?: Rows
  className?: string
}

const defaultRows: Rows = {
  issuingAgency: true,
  respondent: true,
  respondentAddress: true,
  infractionCode: true,
  standardDescription: true,
  hiredBy: true,
}

const ViolationInfoTable: React.FC<Props> = ({
  violation,
  rows = defaultRows,
  className,
}) => {
  const rowsNodes = useMemo(() => getRows(rows, violation), [rows, violation])

  return (
    <table className={clsx(styles['params-table'], className)}>
      <tbody>{rowsNodes}</tbody>
    </table>
  )
}

export default ViolationInfoTable
