import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children?: React.ReactNode
  className?: string
}

const Paper: React.FC<Props> = ({ children, className, ...props }) => {
  return (
    <div {...props} className={clsx(styles['paper'], className)}>
      {children}
    </div>
  )
}

export default Paper
