import React, { useCallback, useEffect, useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import userActions from '@redux/user/actions'
import links from '@routes/links'

import { FormData } from './form'

import View from './view'
import { getQueryParam } from '@services/utils/url'

const mapStateToProps = (state: ReduxState) => ({
  isLoading: state.user.isLoading,
})

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  userActions: bindActionCreators<
    typeof userActions,
    BindedAsyncActions<typeof userActions>
  >(userActions, dispatch),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type NativeProps = RouteComponentProps

type Props = NativeProps & ConnectedProps<typeof connector>

const ResetPassword: React.FC<Props> = ({
  isLoading,
  userActions: { resetPassword },
  history: {
    location: { search },
    replace,
  },
}) => {
  const url = useMemo(
    () =>
      getQueryParam(
        'url',
        search,
        (name: string) => new RegExp('[?&]' + name + '(=(.*)$)')
      ),
    [search]
  )

  useEffect(() => {
    if (!url) {
      replace(links.login())
    }
  }, [url, replace])

  const onSubmit = useCallback(
    ({ password }: FormData) => {
      if (url) {
        resetPassword(password, url, () => replace(links.home()))
      }
    },
    [resetPassword, url, replace]
  )

  return <View isLoading={isLoading} onSubmit={onSubmit} />
}

export default connector(ResetPassword) as React.FC<NativeProps>
