import React from 'react'
import { TabNavItem } from '@components/TabNav/types'

import styles from './styles.module.scss'

export enum TabValue {
  generalInfo = 'general-info',
  addressToReceiveDecision = 'address-to-receive-decision',
  defense = 'defense',
  files = 'files',
}

export const isTabValue = (val: string) =>
  Object.values(TabValue).includes(val as TabValue)

const tabs: TabValue[] = [
  TabValue.generalInfo,
  TabValue.addressToReceiveDecision,
  TabValue.defense,
  TabValue.files,
]

export const tabNavItems: TabNavItem<TabValue>[] = [
  {
    value: TabValue.generalInfo,
    label: (
      <p className={styles['label']}>
        <strong>{'01'}</strong>
        {' General info'}
      </p>
    ),
  },
  {
    value: TabValue.addressToReceiveDecision,
    label: (
      <p className={styles['label']}>
        <strong>{'02'}</strong>
        {' Address to Receive Decision'}
      </p>
    ),
  },
  {
    value: TabValue.defense,
    label: (
      <p className={styles['label']}>
        <strong>{'03'}</strong>
        {' Defense'}
      </p>
    ),
  },
  {
    value: TabValue.files,
    label: (
      <p className={styles['label']}>
        <strong>{'04'}</strong>
        {' Files'}
      </p>
    ),
  },
]

export default tabs
