import React, { useCallback, useEffect } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import IncomeChart from '@common/IncomeChart'
import HalfYearIncomeChart from '@common/HalfYearIncomeChart'

import { IncomePeriodType, emptyHalfYearStats } from '@typings/enums'
import paymentsActions from '@redux/payments/actions'

import styles from '../styles.module.scss'

const mapStateToProps = (state: ReduxState) => ({
  incomeStats: state.payments.incomeStats,
  lastHalfYearStats: state.payments.lastHalfYearStats,
})

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  paymentsActions: bindActionCreators<
    typeof paymentsActions,
    BindedAsyncActions<typeof paymentsActions>
  >(paymentsActions, dispatch),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type Props = ConnectedProps<typeof connector>

const Charts: React.FC<Props> = ({
  incomeStats = {},
  lastHalfYearStats = emptyHalfYearStats,
  paymentsActions: { loadIncomeStats, loadHalfYearIncomeStats },
}) => {
  useEffect(() => {
    loadIncomeStats({ period: IncomePeriodType.daily })
  }, [loadIncomeStats])

  const onPeriodChange = useCallback(
    (period: IncomePeriodType) => loadIncomeStats({ period }),
    [loadIncomeStats]
  )

  useEffect(() => {
    loadHalfYearIncomeStats()
  }, [loadHalfYearIncomeStats])

  return (
    <div className={styles['charts']}>
      <IncomeChart
        incomeStats={incomeStats}
        onPeriodChange={onPeriodChange}
        initialPeriod={IncomePeriodType.daily}
      />
      <HalfYearIncomeChart halfYearStats={lastHalfYearStats} />
    </div>
  )
}

export default connector(Charts) as React.FC
