import React from 'react'
import { TabNavItem } from '@components/TabNav/types'

import styles from './styles.module.scss'

export enum TabValue {
  respondentInfo = 'respondent-info',
  senderInfo = 'sender-info',
  reasonableExcuse = 'reasonable-excuse',
  files = 'files',
}

export const isTabValue = (val: string) =>
  Object.values(TabValue).includes(val as TabValue)

const tabs: TabValue[] = [
  TabValue.respondentInfo,
  TabValue.senderInfo,
  TabValue.reasonableExcuse,
  TabValue.files,
]

export const tabNavItems: TabNavItem<TabValue>[] = [
  {
    value: TabValue.respondentInfo,
    label: (
      <p className={styles['label']}>
        <strong>{'01'}</strong>
        {' Respondent info'}
      </p>
    ),
  },
  {
    value: TabValue.senderInfo,
    label: (
      <p className={styles['label']}>
        <strong>{'02'}</strong>
        {' Sender info'}
      </p>
    ),
  },
  {
    value: TabValue.reasonableExcuse,
    label: (
      <p className={styles['label']}>
        <strong>{'03'}</strong>
        {' Reasonable Excuse'}
      </p>
    ),
  },
  {
    value: TabValue.files,
    label: (
      <p className={styles['label']}>
        <strong>{'04'}</strong>
        {' Files'}
      </p>
    ),
  },
]

export default tabs
