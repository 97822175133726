import React from 'react'
import clsx from 'clsx'

import Header from '@common/Header'
import Head from './Head'
import Charts from './Charts'
import TableSection from './TableSection'

import styles from './styles.module.scss'

interface Props {
  goBack: () => void
  replace: (path: string) => void
  id: string
}

const View: React.FC<Props> = ({ goBack, replace, id }) => {
  return (
    <div className={clsx('page', styles['user-details'])}>
      <Header />
      <div className={clsx('page-content', styles['page-content'])}>
        <Head id={id} goBack={goBack} replace={replace} />
        <Charts />
        <TableSection userId={id} />
      </div>
    </div>
  )
}

export default View
