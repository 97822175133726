import React from 'react'
import { ReactSVG } from 'react-svg'
import clsx from 'clsx'

import Modal from '@components/Modal'

import CloseIcon from '@assets/close-circle.svg'
import FilterIcon from '@assets/filter.svg'

import styles from './styles.module.scss'

interface Props {
  children?: React.ReactNode
  className?: string
  open: boolean
  onRequestClose?: (ev: MouseEvent) => void
  close: () => void
  formProps?: React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >
  isLoading?: boolean
}

const FilterModal: React.FC<Props> = ({
  children,
  className,
  open,
  onRequestClose,
  close,
  formProps,
  isLoading = false,
}) => (
  <Modal
    open={open}
    onRequestClose={onRequestClose}
    className={clsx(styles['filters'], className)}
    wrapperClassName={styles['modal-wrapper']}
    coverScreen={false}
  >
    <button className={styles['close-button']} onClick={close}>
      <ReactSVG src={CloseIcon} className={clsx('svg', styles['close-icon'])} />
    </button>
    <form
      {...formProps}
      className={clsx(styles['content'], styles['form'], formProps?.className)}
    >
      <header className={styles['header']}>
        <div className={styles['title-container']}>
          <ReactSVG src={FilterIcon} className={styles['filter-icon']} />
          <h2>{'Filters'}</h2>
        </div>
        <button
          className={clsx('secondary-button', styles['clear-filters-button'])}
          type="reset"
          disabled={isLoading}
        >
          {'Clear Filters'}
        </button>
      </header>
      {children}
      <footer className={styles['footer']}>
        <button
          className={clsx('primary-button', styles['apply-button'])}
          type="submit"
          disabled={isLoading}
        >
          {'Apply filters'}
        </button>
        <button
          className="small-button"
          onClick={close}
          disabled={isLoading}
          type="button"
        >
          {'Cancel'}
        </button>
      </footer>
    </form>
  </Modal>
)

export default FilterModal
