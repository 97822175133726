import React, { useRef } from 'react'
import clsx from 'clsx'

import { mergeRefs } from '@services'

import styles from './styles.module.scss'

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  type?: 'checkbox' | 'radio'
  containerClassName?: string
}

const BigCheckbox = (
  { type = 'checkbox', containerClassName, children, ...props }: Props,
  ref: React.Ref<HTMLInputElement>
) => {
  const inputRef = useRef<HTMLInputElement>()

  return (
    <label
      className={clsx(
        styles['container'],
        (props.checked || inputRef.current?.checked) &&
          styles['active-container'],
        containerClassName
      )}
      htmlFor={props.id}
    >
      <input
        {...props}
        type={type}
        ref={mergeRefs(ref, inputRef)}
        className={clsx(styles['input'], props.className)}
      />
      <span className={styles['checkmark']}>
        <span className={styles['checkmark-dot']} />
      </span>
      {children}
    </label>
  )
}

export default React.forwardRef(BigCheckbox)
