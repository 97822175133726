import React from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  dotProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
  barValueProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
}

const Bar: React.FC<Props> = ({ dotProps, barValueProps, ...props }) => (
  <div {...props} className={clsx(styles['bar'], props.className)}>
    <div {...dotProps} className={clsx(styles['dot'], dotProps?.className)} />
    <div
      {...barValueProps}
      className={clsx(styles['bar-value'], barValueProps?.className)}
    />
  </div>
)

export default Bar
