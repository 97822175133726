import React, { useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { useFormik } from 'formik'

import Input from '@components/Input'

import useStateWithCb from '@hooks/useStateWithCb'

import styles from '../../styles.module.scss'

interface FormData {
  note: string
}

const formInitial: FormData = {
  note: '',
}

interface Props {
  sendNote: (notes: string[], cb?: () => void) => void
  notes?: string[] | null
}

const Notes: React.FC<Props> = ({ sendNote, notes }) => {
  const [editingNote, setEditingNote] = useState(-1)

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik<
    FormData
  >({
    initialValues: { ...formInitial },
    onSubmit: ({ note }, { setValues }) => {
      const newNotes = [...(notes || [])]
      if (editingNote >= 0) {
        newNotes[editingNote] = note
      } else {
        newNotes.push(note)
      }
      sendNote(newNotes, () => {
        setValues({ ...formInitial })
        setEditingNote(-1)
      })
    },
  })

  const notesInputRef = useRef<HTMLInputElement>(null)

  const [isNotesActive, setIsNotesActive] = useStateWithCb(
    false,
    (isActive) => isActive && notesInputRef.current?.focus()
  )

  const onAddNotesClick = () => {
    setIsNotesActive(true)
    notesInputRef.current?.focus()
  }

  const notesItems = useMemo(
    () =>
      notes ? (
        notes.map((note, index) => (
          <li key={index} className={styles['note-item']}>
            <p
              className={styles['note']}
              onClick={() => {
                setEditingNote(index)
                setFieldValue('note', note)
                setIsNotesActive(true)
              }}
            >
              {note}
            </p>
          </li>
        ))
      ) : (
        <p className={styles['no-notes']}>{"You don't have any notes"}</p>
      ),
    [notes, setEditingNote, setFieldValue, setIsNotesActive]
  )

  const notesAction = useMemo(
    () =>
      isNotesActive ? (
        <form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="note"
            value={values.note}
            inputContainerClassName={styles['note-input']}
            containerClassName={clsx(styles['note-input-container'])}
            disableError={true}
            ref={notesInputRef}
            onChange={handleChange}
            right={
              <button className={styles['add-note-button']} type="submit">
                <span className={styles['plus-icon']} />
                {'Add'}
              </button>
            }
          />
        </form>
      ) : (
        <button
          onClick={onAddNotesClick}
          className={clsx(styles['add-notes-button'])}
          type="submit"
        >
          {'Add Notes'}
        </button>
      ),
    [isNotesActive, values, handleChange]
  )

  return (
    <div className={styles['notes']}>
      <p className={styles['notes-title']}>{'Notes'}</p>
      <ul className={styles['notes-list']}>{notesItems}</ul>

      {notesAction}
    </div>
  )
}

export default Notes
