import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import ViolationsTableWrapper from '../ViolationsTableWrapper'
import ViolationsWrapper from '../wrapper'

import { ViolationType } from '@typings/enums'

const UnhiredViolations: React.FC<RouteComponentProps> = ViolationsWrapper(
  ViolationsTableWrapper<UnhiredViolation>(ViolationType.unhired)
)

export default UnhiredViolations
