import React from 'react'
import clsx from 'clsx'

import SortButton from '@common/SortButton'

import styles from './styles.module.scss'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > {
  onIDClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onNameClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onFOBuildingsClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  onHiredCasesClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  onSanisaverIncomeClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
}

const UserHeadRow: React.FC<Props> = ({
  onIDClick,
  onNameClick,
  onFOBuildingsClick,
  onHiredCasesClick,
  onSanisaverIncomeClick,
  className,
  ...props
}) => {
  return (
    <tr {...props} className={clsx('tr', styles['head-row'], className)}>
      <td>
        <SortButton onClick={onIDClick} className={styles['id']}>
          {'ID #'}
        </SortButton>
      </td>
      <td>
        <SortButton onClick={onNameClick} className={styles['name']}>
          {'Name'}
        </SortButton>
      </td>
      <td>
        <p className={styles['email']}>{'Email'}</p>
      </td>
      <td>
        <p className={styles['phone-number']}>{'Phone number'}</p>
      </td>
      <td>
        <SortButton
          onClick={onFOBuildingsClick}
          className={styles['fo-buildings']}
        >
          {'# FO Buildings'}
        </SortButton>
      </td>
      <td>
        <SortButton
          onClick={onHiredCasesClick}
          className={styles['hired-cases']}
        >
          {'Hired Cases'}
        </SortButton>
      </td>
      <td>
        <SortButton
          onClick={onSanisaverIncomeClick}
          className={styles['sanisaver-income']}
        >
          {'Sanisaver Income'}
        </SortButton>
      </td>
    </tr>
  )
}

export default UserHeadRow
