import React from 'react'
import clsx from 'clsx'

import {
  HearingStatusClassName,
  HearingStatusLabel,
  HearingStatusType,
} from '@typings/enums'

import styles from './styles.module.scss'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  type: HearingStatusType
  children?: string
}

const HearingStatus: React.FC<Props> = ({
  type,
  children = HearingStatusLabel[type] || type,
  className,
  ...props
}) => (
  <div
    className={clsx(
      styles['hearing-status'],
      styles[HearingStatusClassName[type]],
      className
    )}
    {...props}
  >
    <p className={styles['hearing-status-text']}>{children}</p>
  </div>
)

export default HearingStatus
