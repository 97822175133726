import React, { useCallback } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Input from '@components/Input'

import violationsActions from '@redux/violations/actions'

import styles from '../styles.module.scss'

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  violationsActions: bindActionCreators<
    typeof violationsActions,
    BindedAsyncActions<typeof violationsActions>
  >(violationsActions, dispatch),
})

const connector = connect(undefined, mapDispatchToProps)

export interface FormData {
  search: string
}

type NativeProps = {
  setSearchActive: (active: boolean) => void
}

type Props = NativeProps & ConnectedProps<typeof connector>

const SearchForm: React.FC<Props> = ({
  violationsActions: { filter },
  setSearchActive,
}) => {
  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchActive(value.length > 0)
    filter({ search: value || undefined })
  }, [])

  return (
    <Input
      type="text"
      name="search"
      onChange={onChange}
      inputContainerClassName={styles['search']}
      placeholder="search"
      containerClassName={styles['search-container']}
      disableError={true}
    />
  )
}

export default connector(SearchForm) as React.FC<NativeProps>
