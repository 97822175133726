import React, { useCallback } from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import clsx from 'clsx'

import FormData from './form'
import Input from '@components/Input'
import InfoTooltip from '@common/InfoTooltip'

import { hearingByMailUrl, hearingInPerson } from '@constants/nyc'
import { toArray } from '@services/utils/array'

import sharedStyles from '../styles.module.scss'
import styles from '@components/FileInput/DefaultFile/styles.module.scss'
import { ReactSVG } from 'react-svg'
import UploadIcon from '@assets/upload.svg'

interface Props {
  violation: ViolationDetails | null
  form: FormData
  touched: FormikTouched<FormData>
  errors: FormikErrors<FormData>
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  setFieldValue: <F extends keyof FormData = keyof FormData>(
    field: F,
    value: FormData[F],
    shouldValidate?: boolean
  ) => void
}

const Files: React.FC<Props> = ({
  form,
  touched,
  errors,
  onChange,
  setFieldValue,
  violation,
}) => {
  const onChangeFile = useCallback(
    (file: File | File[] | undefined) =>
      setFieldValue('files', toArray(file), false),
    [setFieldValue]
  )

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
    <React.Fragment>
      <div
        className={clsx(sharedStyles['section'], sharedStyles['files-section'])}
      >
        <div className={sharedStyles['section-head']}>
          <h2 className={sharedStyles['section-title']}>{'File Uploads'}</h2>
          <p className={sharedStyles['section-description']}>
            {'You can also upload any supporting documentation which you may have ' +
              '(pictures, letters etc.) Upload .jpeg, .pdf, .doc, files - Total file size cannot exceed 3Mb.'}
            <InfoTooltip>
              {'If you have more than 3 documents to upload or the total size of the documents is more than 3 MB, ' +
                'then One-Click Hearings may not be the right option for you. You may choose to have your '}
              <a
                className="label-link"
                target="_blank"
                rel="noreferrer"
                href={hearingByMailUrl}
              >
                {'hearing by mail'}
              </a>
              {' or attend an '}
              <a
                className="label-link"
                target="_blank"
                rel="noreferrer"
                href={hearingInPerson}
              >
                {'in-person hearing'}
              </a>
              {'.'}
            </InfoTooltip>
          </p>
        </div>
        <div className={sharedStyles['inputs']}>
          <Input
            type="file"
            name="files"
            file={form.files}
            onChangeFile={onChangeFile}
            multiple={true}
            accept=".jpeg,.pdf,.doc,.docx"
            max={2}
          />
          <div
            onClick={() => {
              // @ts-ignore
              window.open(violation.ecbAuthUrl, '_blank').focus()
            }}
            className={clsx(styles['file'])}
          >
            <ReactSVG
              src={UploadIcon}
              className={clsx('svg', styles['upload-icon'])}
            />
            <p className={styles['text']}>{`ECB AUTH`}</p>
          </div>
        </div>
      </div>

      <div className={sharedStyles['section']}>
        <div className={sharedStyles['section-head']}>
          <h2 className={sharedStyles['section-title']}>
            {'Certification'}
            <span className="asterisk" />
          </h2>
        </div>
        <div className={sharedStyles['inputs']}>
          <Input
            type="checkbox"
            name="certification"
            checked={form.certification}
            onChange={onChange}
            label={
              'I certify under penalty of perjury that to the best ' +
              'of my knowledge all the information I included on this e-form is true.'
            }
            labelClassName="uppercase"
            error={touched.certification && errors.certification}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Files
