import React, { useCallback, useEffect, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'
import clsx from 'clsx'
import { ValueType } from 'react-select'
import { useFormik } from 'formik'

import FilterModal from '@common/FilterModal'
import Select, { OptionType, getMultiSelectValue } from '@components/Select'
import Status from '@components/Status'
import BigCheckbox from '@components/BigCheckbox'

import paymentsActions from '@redux/payments/actions'
import buildingsActions from '@redux/buildings/actions'
import usersActions from '@redux/users/actions'
import { getStatusByType } from '@services/payment'
import { emptyFilter as emptyBuildingsFilter } from '@services/building'
import { emptyFilter as emptyUsersFilter } from '@services/user'

import { BalanceType, PaymentStatusKeyToType } from '@typings/enums'

import styles from './styles.module.scss'

const mapStateToProps = (state: ReduxState) => ({
  users: state.users.users,
  buildings: state.buildings.buildings,
  statuses: state.payments.statuses,
  isLoading: state.payments.isLoading,
  usersSearch: state.users.params.search,
  buildingsSearch: state.buildings.params.search,
})

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  paymentsActions: bindActionCreators<
    typeof paymentsActions,
    BindedAsyncActions<typeof paymentsActions>
  >(paymentsActions, dispatch),
  buildingsActions: bindActionCreators<
    typeof buildingsActions,
    BindedAsyncActions<typeof buildingsActions>
  >(buildingsActions, dispatch),
  usersActions: bindActionCreators<
    typeof usersActions,
    BindedAsyncActions<typeof usersActions>
  >(usersActions, dispatch),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

interface NativeProps {
  open: boolean
  close: () => void
}

type Props = NativeProps & ConnectedProps<typeof connector>

interface FormData {
  userNames: ValueType<OptionType>
  buildings: ValueType<OptionType>
  paymentStatus: ValueType<OptionType<PaidStatusType>>
  feeType: boolean
  fineType: boolean
}

const formInitial: FormData = {
  userNames: [],
  buildings: [],
  paymentStatus: [],
  feeType: true,
  fineType: true,
}

const Filters: React.FC<Props> = ({
  open,
  close,
  users = [],
  usersSearch,
  buildings = [],
  buildingsSearch,
  statuses = [],
  isLoading,
  paymentsActions: { filter, resetFilters, loadStatuses },
  buildingsActions: { load: loadBuildings },
  usersActions: { load: loadUsers },
}) => {
  const {
    values,
    setFieldValue,
    handleSubmit,
    handleReset,
    handleChange,
  } = useFormik<FormData>({
    initialValues: {
      ...formInitial,
    },
    onSubmit: ({ userNames, buildings, paymentStatus, feeType, fineType }) => {
      filter({
        users_ids: getMultiSelectValue(userNames),
        buildings_ids: getMultiSelectValue(buildings),
        statuses: getMultiSelectValue(paymentStatus),
        type:
          feeType === fineType
            ? undefined
            : feeType
            ? BalanceType.fee
            : fineType
            ? BalanceType.fine
            : undefined,
      })
    },
    onReset: (_values, helpers) => {
      resetFilters(() => helpers.setValues({ ...formInitial }))
    },
  })

  useEffect(() => {
    loadUsers({ page: 1, ...emptyUsersFilter })
  }, [loadUsers])

  useEffect(() => {
    loadStatuses()
  }, [loadStatuses])

  useEffect(() => {
    loadBuildings({ page: 1, ...emptyBuildingsFilter })
  }, [loadBuildings])

  const usersOptions: OptionType[] = useMemo(
    () =>
      users.map((user) => ({
        value: String(user.id),
        label: `${user.firstName} ${user.lastName}`,
      })),
    [users]
  )

  const buildingsOptions: OptionType[] = useMemo(
    () =>
      buildings.map((building) => ({
        value: String(building.id),
        label: building.address,
      })),
    [buildings]
  )

  const statusTypeOptions: OptionType[] = useMemo(
    () =>
      statuses.map((value) => ({
        value: String(value),
        label: <Status type={getStatusByType(PaymentStatusKeyToType[value])} />,
      })),
    [statuses]
  )

  const onUserNamesChange = useCallback(
    (e: ValueType<OptionType>) => setFieldValue('userNames', e, false),
    [setFieldValue]
  )

  const onUsersInputChange = useCallback(
    (newValue: string) => {
      if (!newValue && !usersSearch) {
        return
      }
      if (newValue !== usersSearch) {
        loadUsers({ search: newValue || undefined })
      }
    },
    [setFieldValue, loadUsers, usersSearch]
  )

  const onBuildingsChange = useCallback(
    (e: ValueType<OptionType>) => setFieldValue('buildings', e, false),
    [setFieldValue]
  )

  const onBuildingsInputChange = useCallback(
    (newValue: string) => {
      if (!newValue && !buildingsSearch) {
        return
      }
      if (newValue !== buildingsSearch) {
        loadBuildings({ search: newValue || undefined })
      }
    },
    [setFieldValue, loadBuildings, buildingsSearch]
  )

  const onPaymentStatusChange = useCallback(
    (e: ValueType<OptionType>) => setFieldValue('paymentStatus', e, false),
    [setFieldValue]
  )

  return (
    <FilterModal
      open={open}
      close={close}
      formProps={{ onSubmit: handleSubmit, onReset: handleReset }}
      isLoading={isLoading}
    >
      <Select
        isMulti={true}
        value={values.userNames}
        options={usersOptions}
        onChange={onUserNamesChange}
        className={clsx(styles['select'], styles['user-select'])}
        placeholder="All"
        onInputChange={onUsersInputChange}
        label="Users"
        labelClassName={styles['select-label']}
        containerClassName={styles['select-container']}
      />

      <Select
        isMulti={true}
        value={values.buildings}
        options={buildingsOptions}
        onChange={onBuildingsChange}
        className={clsx(styles['select'], styles['building-select'])}
        placeholder="All"
        onInputChange={onBuildingsInputChange}
        label="Building"
        labelClassName={styles['select-label']}
        containerClassName={styles['select-container']}
      />

      <Select
        isMulti={true}
        value={values.paymentStatus}
        options={statusTypeOptions}
        onChange={onPaymentStatusChange}
        className={clsx(styles['select'], styles['status-select'])}
        placeholder="All"
        label="Payment Status"
        labelClassName={styles['select-label']}
        containerClassName={styles['select-container']}
      />

      <div
        className={clsx(
          styles['select-container'],
          styles['select-with-margin']
        )}
      >
        <label className={clsx('input-label', styles['select-label'])}>
          {'Payment Type'}
        </label>
        <div className={styles['payment-types']}>
          <BigCheckbox
            name="feeType"
            checked={values.feeType}
            onChange={handleChange}
            containerClassName={styles['payment-type']}
          >
            {'Fee'}
          </BigCheckbox>
          <BigCheckbox
            name="fineType"
            checked={values.fineType}
            onChange={handleChange}
            containerClassName={styles['payment-type']}
          >
            {'Fine'}
          </BigCheckbox>
        </div>
      </div>
    </FilterModal>
  )
}

export default connector(Filters) as React.FC<NativeProps>
