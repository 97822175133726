enum PaymentSortType {
  id = 'transactions.id',
  userName = 'user.name',
  amount = 'transactions.amount',
  status = 'transactions.status',
}

enum PaymentStatusType {
  successful_sanisaver = 'SUCCESSFUL_CHARGE_FOR_SANISAVER',
  successful_nyc = 'SUCCESSFUL_CHARGE_FOR_NYC',
  failed = 'FAILED_CHARGE',
}

enum PaymentStatusTypeKey {
  successful_sanisaver = 1,
  successful_nyc = 2,
  failed = 3,
}

const PaymentStatusKeyToType = {
  [PaymentStatusTypeKey.successful_sanisaver]:
    PaymentStatusType.successful_sanisaver,
  [PaymentStatusTypeKey.successful_nyc]: PaymentStatusType.successful_nyc,
  [PaymentStatusTypeKey.failed]: PaymentStatusType.failed,
}

const paymentsFilterTypes: PaymentFilterType[] = [
  'users_ids',
  'buildings_ids',
  'statuses',
  'type',
  'search',
]

enum IncomePeriodType {
  daily = 'day',
  weekly = 'week',
  monthly = 'month',
  yearly = 'year',
}

const IncomePeriodTypeLabel = {
  [IncomePeriodType.daily]: 'daily',
  [IncomePeriodType.weekly]: 'weekly',
  [IncomePeriodType.monthly]: 'monthly',
  [IncomePeriodType.yearly]: 'yearly',
}

const incomePeriodTypes = [
  IncomePeriodType.daily,
  IncomePeriodType.weekly,
  IncomePeriodType.monthly,
  IncomePeriodType.yearly,
]

const emptyHalfYearStats: HalfYearStats = {
  data: {},
  total: {
    income: 0,
    increased: false,
    percent: 0,
  },
}

export {
  PaymentSortType,
  PaymentStatusType,
  paymentsFilterTypes,
  PaymentStatusTypeKey,
  PaymentStatusKeyToType,
  IncomePeriodType,
  IncomePeriodTypeLabel,
  incomePeriodTypes,
  emptyHalfYearStats,
}

export default {}
