import React from 'react'
import clsx from 'clsx'

import Header from '@common/Header'

import Form, { FormData } from './form'

import styles from './styles.module.scss'

interface Props {
  isLoading: boolean
  onSubmit: (values: FormData) => void
  error: string
  resetError: () => void
}

const View: React.FC<Props> = ({ isLoading, onSubmit, error, resetError }) => (
  <div className={clsx('page', styles['login-page'])}>
    <Header />
    <div className={clsx('page-content', styles['login-page-content'])}>
      <div className={clsx('card', 'big-card', styles['login-card'])}>
        <h1 className={clsx('black-text', styles['title'])}>{'Login'}</h1>
        <p className={clsx('additional-text', styles['subtitle'])}>
          {'Welcome back. Please Login to your account'}
        </p>
        <Form
          isLoading={isLoading}
          onSubmit={onSubmit}
          error={error}
          resetError={resetError}
        />
        <p className={clsx('error-text', styles['error-text'])}>{error}</p>
      </div>
      <p className={clsx('additional-text', styles['footer-text'])}>
        {
          'If you do not have login information, please check you email or contact administrator'
        }
      </p>
    </div>
  </div>
)

export default View
