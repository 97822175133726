import React, { useCallback, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import userActions from '@redux/user/actions'

import { FormData } from './cards/FormCard/form'

import View, { PageState } from './view'

const mapStateToProps = (state: ReduxState) => ({
  isLoading: state.user.isLoading,
})

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  userActions: bindActionCreators<
    typeof userActions,
    BindedAsyncActions<typeof userActions>
  >(userActions, dispatch),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type NativeProps = RouteComponentProps

type Props = NativeProps & ConnectedProps<typeof connector>

const ForgotPassword: React.FC<Props> = ({
  isLoading,
  userActions: { forgotPassword },
}) => {
  const [pageState, setPageState] = useState(PageState.form)

  const onSubmit = useCallback(
    ({ email }: FormData) => {
      forgotPassword(email, () => setPageState(PageState.complete))
    },
    [forgotPassword]
  )

  return (
    <View isLoading={isLoading} pageState={pageState} onSubmit={onSubmit} />
  )
}

export default connector(ForgotPassword) as React.FC<NativeProps>
