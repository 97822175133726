import { ValueType } from 'react-select'
import { OptionType } from '@components/Select'

export const getSelectValue = <T extends string | number>(
  e: ValueType<OptionType<T>>
): T | undefined => {
  if (e === undefined || e === null) {
    return undefined
  } else if (Array.isArray(e)) {
    return e.map((e) => e.value)[0]
  } else {
    return ((e as unknown) as OptionType).value as T
  }
}

export const getMultiSelectValue = <T extends string>(
  e: ValueType<OptionType>
): T[] | undefined => {
  if (e === undefined || e === null) {
    return undefined
  } else if (Array.isArray(e)) {
    return e.length > 0 ? e.map((e) => e.value) : undefined
  } else {
    return [((e as unknown) as OptionType).value as T]
  }
}

export default {
  getSelectValue,
  getMultiSelectValue,
}
