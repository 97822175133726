import React, { useMemo } from 'react'
import clsx from 'clsx'
import { ReactSVG } from 'react-svg'

import BarChart from '@components/BarChart'

import { formatChartValueAxis } from '@services/chart'
import { getLastHalfYearData } from '@services/incomeChart'
import { formatAmountK } from '@services/money'

import StonksUpIcon from '@assets/stonks-up.svg'

import styles from './styles.module.scss'

interface Props {
  halfYearStats: HalfYearStats
  variant?: 'blue' | 'white'
}

const HalfYearIncomeChart: React.FC<Props> = ({
  halfYearStats,
  variant = 'blue',
}) => {
  const monthsData = useMemo(() => getLastHalfYearData(halfYearStats.data), [
    halfYearStats,
  ])

  const halfYearTotal = useMemo(
    () => formatAmountK(halfYearStats.total.income, '', ' '),
    [halfYearStats]
  )

  return (
    <div
      className={clsx(
        styles['chart-container'],
        styles['bar-chart-container'],
        styles[`bar-chart-container-${variant}`]
      )}
    >
      <div className={styles['chart-heading']}>
        <p className={styles['title-small']}>{'6 months stats'}</p>
        <p className={styles['title']}>
          <sup>{'$ '}</sup>
          {halfYearTotal}
        </p>
      </div>
      <BarChart
        data={monthsData}
        className={styles['bar-chart']}
        labelItemPropsExtractor={() => ({
          className: styles['label'],
        })}
        labelExtractor={formatChartValueAxis}
        barPropsExtractor={() => ({
          barValueProps: { className: styles['bar'] },
          dotProps: { className: styles['bar-dot'] },
        })}
      />
      <footer className={styles['stats']}>
        <p className={styles['text']}>
          <span
            className={styles['big-text']}
          >{`${halfYearStats.total.percent.toFixed(0)}% `}</span>
          {'Successful cases'}
        </p>
        <ReactSVG src={StonksUpIcon} className={styles['stonks-icon']} />
      </footer>
    </div>
  )
}

export default HalfYearIncomeChart
