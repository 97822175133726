import React, { useCallback, useRef, useState } from 'react'
import clsx from 'clsx'
import { ReactSVG } from 'react-svg'

import UploadIcon from '@assets/upload.svg'
import CloseIcon from '@assets/close-circle.svg'

import styles from './styles.module.scss'

interface Props {
  file?: File
  openFile: () => void
  updateFiles?: (file: File | File[]) => void
  removeFile?: (file: File) => void
}

const DefaultFile: React.FC<Props> = ({
  file,
  openFile,
  updateFiles,
  removeFile,
}) => {
  const dragCounter = useRef(0)
  const [isDragging, setIsDragging] = useState(false)

  const onDrag = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
  }, [])

  const onDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
  }, [])

  const onDragEnter = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation()
      e.preventDefault()
      ++dragCounter.current
      if (
        !file &&
        updateFiles &&
        e.dataTransfer &&
        e.dataTransfer.items &&
        e.dataTransfer.items.length > 0
      ) {
        setIsDragging(true)
      }
    },
    [dragCounter, setIsDragging, updateFiles, file]
  )

  const onDragLeave = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation()
      e.preventDefault()
      --dragCounter.current
      if (dragCounter.current === 0) {
        setIsDragging(false)
      }
    },
    [dragCounter, setIsDragging]
  )

  const onDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation()
      e.preventDefault()
      setIsDragging(false)
      if (
        !file &&
        e.dataTransfer &&
        e.dataTransfer.files &&
        e.dataTransfer.files.length > 0
      ) {
        updateFiles?.(Array.from(e.dataTransfer.files))

        e.dataTransfer.clearData()
        dragCounter.current = 0
      }
    },
    [dragCounter, setIsDragging, file, updateFiles]
  )

  const remove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      file && removeFile?.(file)
    },
    [file, removeFile]
  )

  const text = file ? (
    file.name
  ) : isDragging ? (
    <React.Fragment>
      <span className={styles['blue']}>{'Drop'}</span>
      {' your files here'}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <span className={styles['blue']}>{'Upload'}</span>
      {' or drag and drop your File here'}
    </React.Fragment>
  )

  return (
    <div
      className={clsx(styles['file'], isDragging && styles['is-dragging'])}
      onClick={openFile}
      onDrag={onDrag}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <button
        onClick={remove}
        type="button"
        className={clsx(
          styles['close-btn'],
          !file && styles['close-btn-hidden']
        )}
      >
        <ReactSVG
          src={CloseIcon}
          className={clsx('svg', styles['close-icon'])}
        />
      </button>
      <ReactSVG
        src={UploadIcon}
        className={clsx('svg', styles['upload-icon'])}
      />
      <p className={styles['text']}>{text}</p>
    </div>
  )
}

export default DefaultFile
