import React from 'react'
import clsx from 'clsx'

import SortButton from '@common/SortButton'

import styles from './styles.module.scss'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > {
  onIdClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onNameClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onAmountClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  onStatusClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
}

const PaymentHeadRow: React.FC<Props> = ({
  onIdClick,
  onNameClick,
  onAmountClick,
  onStatusClick,
  className,
  ...props
}) => (
  <tr {...props} className={clsx('tr', styles['head-row'], className)}>
    <td>
      {' '}
      <SortButton className={styles['id']} onClick={onIdClick}>
        {'ID #'}
      </SortButton>
    </td>
    <td>
      <SortButton className={styles['name']} onClick={onNameClick}>
        {'Name'}
      </SortButton>
    </td>
    <td>
      <p className={styles['email']}>{'Email'}</p>
    </td>
    <td>
      <p className={styles['phone-number']}>{'Phone Number'}</p>
    </td>
    <td>
      <p className={styles['building']}>{'Building'}</p>
    </td>
    <td>
      <p className={styles['violation']}>{'Violation #'}</p>
    </td>
    <td>
      <SortButton className={styles['amount']} onClick={onAmountClick}>
        {'Amount/Type'}
      </SortButton>
    </td>
    <td>
      <SortButton className={styles['status']} onClick={onStatusClick}>
        {'Status'}
      </SortButton>
    </td>
  </tr>
)

export default PaymentHeadRow
