import { svgNS } from '@constants/ns'
import { createUniqueId } from '@services/dom'

export const addHovers = (
  containerRef: Element,
  rem: (num: number) => number,
  styles: {
    readonly [key: string]: string
  },
  padding?: boolean
) => {
  const svg = containerRef.querySelector('svg')
  const g = svg?.querySelector('g')
  const path = svg?.querySelector('path')
  if (!svg || !g || !path) {
    return
  }

  const gRect = g.getBoundingClientRect()

  const slices = Array.from(
    svg.querySelectorAll('g > rect') || []
  ) as SVGRectElement[]

  const callbacks: (() => void)[] = []

  const pathSlices = slices.map((slice, index, self) => {
    const container = document.createElementNS(svgNS, 'g')

    const id = createUniqueId()

    const mask = document.createElementNS(svgNS, 'clipPath')
    mask.id = id

    const section = slice.cloneNode(true) as Element
    section.setAttributeNS(null, 'rx', String(rem(8)))
    mask.append(section)

    container.appendChild(mask)

    const contentContainer = document.createElementNS(svgNS, 'g')
    contentContainer.setAttributeNS(null, 'clip-path', `url(#${id})`)

    const rect = document.createElementNS(svgNS, 'rect')
    rect.setAttributeNS(null, 'x', '0')
    rect.setAttributeNS(null, 'y', '0')
    rect.setAttributeNS(null, 'width', String(gRect.width))
    rect.setAttributeNS(null, 'height', String(gRect.height))

    const pathSlice = path.cloneNode(true) as SVGPathElement

    contentContainer.append(rect, pathSlice)

    container.appendChild(contentContainer)
    container.classList.add(styles['path-section'])

    if (!(padding && (index === 0 || index === self.length - 1))) {
      const mouseOver = () => {
        container.classList.add(styles['hover'])
      }
      const mouseLeave = () => {
        container.classList.remove(styles['hover'])
      }
      slice.addEventListener('mouseenter', mouseOver)
      slice.addEventListener('mouseleave', mouseLeave)
      callbacks.push(() => {
        slice.removeEventListener('mouseenter', mouseOver)
        slice.removeEventListener('mouseleave', mouseLeave)
      })
    }

    return container
  })

  const sectionsPaths = document.createElementNS(svgNS, 'g')

  sectionsPaths.append(...pathSlices)
  g.insertBefore(sectionsPaths, path.nextSibling)

  return () => {
    callbacks.forEach((cb) => cb())
    sectionsPaths.remove()
  }
}

export default {
  addHovers,
}
