import React, { useEffect, useMemo, useRef } from 'react'
import { ReactSVG } from 'react-svg'
import clsx from 'clsx'
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr'

import useStateRef from '@hooks/useStateRef'

import useStateProp from '@hooks/useStateProp'
import { createUniqueId } from '@services/dom'
import { defaultShortDateFormat } from '@constants/date'

import CalendarIcon from '@assets/calendar.svg'

import styles from './styles.module.scss'

interface Props extends DateTimePickerProps {
  calendarContainerClassName?: string
  calendarContainerRef?:
    | ((instance: HTMLDivElement | null) => void)
    | React.MutableRefObject<HTMLDivElement | undefined>
    | null
  containerClassName?: string
  wrapperClassName?: string

  label?: React.ReactNode
  labelClassName?: string
  labelAsterisk?: boolean

  error?: React.ReactNode
  errorClassName?: string
  disableError?: boolean
}

const CalendarInput: React.FC<Props> = ({
  calendarContainerClassName,
  calendarContainerRef,
  containerClassName,
  wrapperClassName,

  label,
  labelClassName,
  labelAsterisk,
  error,
  errorClassName,
  disableError,

  ...props
}) => {
  const [id, setId] = useStateProp(props.id)

  const calendarRef = useStateRef<HTMLDivElement>(null)
  const inputRef = useStateRef<HTMLInputElement>(null)

  const observer = useRef<MutationObserver>()

  useEffect(() => {
    if (!id) {
      setId(createUniqueId())
    }
  }, [id])

  useEffect(() => {
    if (calendarRef.current) {
      const callback: MutationCallback = (_mutations, _observer) => {
        if (calendarRef.current && inputRef.current) {
          calendarRef.current.style.width = window.getComputedStyle(
            inputRef.current
          ).width
        }
      }
      observer.current = new MutationObserver(callback)
      observer.current.observe(calendarRef.current, {
        attributes: true,
        attributeFilter: ['class'],
      })
      return () => observer.current?.disconnect()
    }
  }, [calendarRef.current])

  const options: Props['options'] = {
    ...props.options,

    dateFormat: props.options?.dateFormat || defaultShortDateFormat,

    onReady: (date, dateStr, instance) => {
      instance.calendarContainer.classList.add(styles['calendar'])
      if (calendarContainerClassName) {
        instance.calendarContainer.classList.add(calendarContainerClassName)
      }

      calendarRef(instance.calendarContainer)
      inputRef(instance.input)

      instance.input.classList.add(styles['input'])
      if (calendarContainerRef) {
        if (typeof calendarContainerRef === 'function') {
          calendarContainerRef(instance.calendarContainer)
        } else if (calendarContainerRef) {
          calendarContainerRef.current = instance.calendarContainer
        }
      }

      if (Array.isArray(props.options?.onReady)) {
        props.options?.onReady.forEach((hook) =>
          hook?.(date, dateStr, instance)
        )
      } else {
        props.options?.onReady?.(date, dateStr, instance)
      }
    },
  }

  const errorNode = useMemo(() => {
    if (disableError) {
      return null
    }
    if (error === null) {
      error = undefined
    }

    const errorType = typeof error
    switch (errorType) {
      case 'string':
      case 'number': {
        return <p className={clsx('error-text', errorClassName)}>{error}</p>
      }
      case 'undefined':
      case 'boolean': {
        return <div className="error-placeholder" />
      }
      default: {
        return error
      }
    }
  }, [error, errorClassName, disableError])

  const labelNode = useMemo(
    () =>
      label && (
        <label htmlFor={id} className={clsx('input-label', labelClassName)}>
          {label}
          {labelAsterisk ? (
            <span className={styles['label-asterisk']}>{'*'}</span>
          ) : null}
        </label>
      ),
    [label, labelClassName, labelAsterisk, id]
  )

  return (
    <div className={clsx(styles['calendar-input-wrapper'], wrapperClassName)}>
      {labelNode}
      <div
        className={clsx(
          styles['calendar-input-container'],
          error && styles['error'],
          containerClassName
        )}
      >
        <Flatpickr {...props} id={id} options={options} />
        <ReactSVG src={CalendarIcon} className={clsx('svg', styles['right'])} />
      </div>
      {errorNode}
    </div>
  )
}

export default CalendarInput
