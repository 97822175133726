enum ActionType {
  LOADING = '[users]: loading',
  ERROR = '[users]: error',
  RESET = '[users]: reset',

  LOAD_BEGIN = '[users]: get begin',
  LOAD_SUCCESS = '[users]: get success',
  LOAD_ERROR = '[users]: get error',

  LOAD_DETAILS_BEGIN = '[users]: get details begin',
  LOAD_DETAILS_SUCCESS = '[users]: get details success',
  LOAD_DETAILS_ERROR = '[users]: get details error',
}

type LoadingAction = ReduxAction<ActionType.LOADING, boolean>

type ErrorAction = ReduxAction<ActionType.ERROR, ErrorType>

type ResetAction = ReduxAction<ActionType.RESET>

type LoadBeginAction = ReduxAction<ActionType.LOAD_BEGIN>

type LoadSuccessAction = ReduxAction<
  ActionType.LOAD_SUCCESS,
  {
    users: User[]
    params: UsersParams
  }
>

type LoadErrorAction = ReduxAction<ActionType.LOAD_ERROR, ErrorType>

type LoadDetailsBeginAction = ReduxAction<ActionType.LOAD_DETAILS_BEGIN>

type LoadDetailsSuccessAction = ReduxAction<
  ActionType.LOAD_DETAILS_SUCCESS,
  User
>

type LoadDetailsErrorAction = ReduxAction<
  ActionType.LOAD_DETAILS_ERROR,
  ErrorType
>

export type Action =
  | LoadingAction
  | ErrorAction
  | ResetAction
  | LoadBeginAction
  | LoadSuccessAction
  | LoadErrorAction
  | LoadDetailsBeginAction
  | LoadDetailsSuccessAction
  | LoadDetailsErrorAction

export default ActionType
