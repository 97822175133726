import ActionType, { Action } from './types'
import { defaultPerPage } from '@config'

export interface IState {
  isLoading: boolean
  payments: Payment[]
  params: PaymentsParams

  statuses: PaymentStatusTypeKey[]
  statusesLoadedAt: number

  incomeStats: IncomeStats
  lastHalfYearStats: HalfYearStats

  error: ErrorType | null
}

const initialState: IState = {
  isLoading: false,
  payments: [],
  params: {
    page: 0,
    per_page: defaultPerPage,
    totalPages: 0,
  },

  statuses: [],
  statusesLoadedAt: 0,

  incomeStats: {},
  lastHalfYearStats: {
    data: {},
    total: {
      income: 0,
      increased: false,
      percent: 0,
    },
  },

  error: null,
}

const reducer = (state = initialState, action: Action): IState => {
  switch (action.type) {
    case ActionType.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }
    case ActionType.RESET: {
      return {
        ...initialState,
      }
    }
    case ActionType.LOAD_BEGIN:
    case ActionType.LOAD_STATUSES_BEGIN: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case ActionType.LOAD_INCOME_STATS_BEGIN: {
      return {
        ...state,
        incomeStats: {},
        isLoading: true,
      }
    }
    case ActionType.LOAD_HALF_YEAR_INCOME_STATS_BEGIN: {
      return {
        ...state,
        lastHalfYearStats: { ...initialState.lastHalfYearStats },
        isLoading: true,
      }
    }
    case ActionType.LOAD_SUCCESS: {
      const {
        payments,
        params: { page, per_page, totalPages, ...params },
      } = action.payload
      return {
        ...state,
        payments,
        params: {
          ...state.params,
          ...params,
          page,
          per_page,
          totalPages,
        },
        isLoading: false,
      }
    }
    case ActionType.LOAD_STATUSES_SUCCESS: {
      return {
        ...state,
        statuses: action.payload,
        statusesLoadedAt: new Date().getTime(),
        isLoading: false,
      }
    }
    case ActionType.LOAD_INCOME_STATS_SUCCESS: {
      return {
        ...state,
        incomeStats: Array.isArray(action.payload)
          ? Object(action.payload)
          : action.payload,
        isLoading: false,
      }
    }
    case ActionType.LOAD_HALF_YEAR_INCOME_STATS_SUCCESS: {
      return {
        ...state,
        lastHalfYearStats: action.payload,
        isLoading: false,
      }
    }
    case ActionType.ERROR:
    case ActionType.LOAD_ERROR:
    case ActionType.LOAD_STATUSES_ERROR:
    case ActionType.LOAD_INCOME_STATS_ERROR:
    case ActionType.LOAD_HALF_YEAR_INCOME_STATS_ERROR: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default reducer
