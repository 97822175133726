import React, { useEffect } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import links from '@routes/links'

const mapStateToProps = (state: ReduxState) => ({ user: state.user.user })

const connector = connect(mapStateToProps)

type NativeProps = RouteComponentProps

type Props = NativeProps & ConnectedProps<typeof connector>

const NotFound: React.FC<Props> = ({ user, history }) => {
  useEffect(() => {
    history.replace(user ? links.home() : links.login())
  }, [])
  return null
}

export default connector(NotFound) as React.FC<NativeProps>
