import React from 'react'
import clsx from 'clsx'

import Header from '@common/Header'
import Link from '@components/Link'

import Form, { FormData } from './form'

import styles from './styles.module.scss'
import links from '@routes/links'

interface Props {
  isLoading: boolean
  onSubmit: (values: FormData) => void
}

const View: React.FC<Props> = ({ isLoading, onSubmit }) => {
  return (
    <div className={clsx('page', styles['reset-password-page'])}>
      <Header />
      <div
        className={clsx('page-content', styles['reset-password-page-content'])}
      >
        <div
          className={clsx('card', 'big-card', styles['reset-password-card'])}
        >
          <h1 className={clsx('black-text', styles['title'])}>
            {'New Password'}
          </h1>
          <p className={clsx('additional-text', styles['subtitle'])}>
            {'Create new password for your account'}
          </p>
          <Form isLoading={isLoading} onSubmit={onSubmit} />
          <Link
            to={links.login()}
            className={clsx('link', styles['login-link'])}
          >
            {'Back to Login'}
          </Link>
        </div>
        <p className={clsx('additional-text', styles['footer-text'])}>
          {
            'If you do not have login information, please check you email or contact administrator'
          }
        </p>
      </div>
    </div>
  )
}

export default View
