import { buildingsFilterTypes } from '@typings/enums'

export const emptyFilter: BuildingFilters = buildingsFilterTypes.reduce(
  (acc, filter) => ({ ...acc, [filter]: undefined }),
  {}
)

export default {
  emptyFilter,
}
