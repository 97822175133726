import React from 'react'
import clsx from 'clsx'

import Header from '@common/Header'
import Heading from '@common/Heading'
import BaseInfo from './BaseInfo'
import Form from './Form'

import styles from './styles.module.scss'

interface Props {
  goBack: () => void
}

const View: React.FC<Props> = ({ goBack }) => {
  return (
    <div className={clsx('page', styles['violation-hearing-form'])}>
      <Header />
      <div className={clsx('page-content', styles['page-content'])}>
        <Heading goBack={goBack} className={styles['title-container']}>
          {'Online Hearing Form'}
        </Heading>
        <Form className={styles['form']} />
        <BaseInfo className={styles['base-info']} />
      </div>
    </div>
  )
}

export default View
