import React from 'react'

import { RouteComponentProps } from 'react-router-dom'

import View from './view'

type Props = RouteComponentProps

const Users: React.FC<Props> = () => {
  return <View />
}

export default Users
