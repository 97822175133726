import { usersFilterTypes } from '@typings/enums'

export const emptyFilter: UsersFilters = usersFilterTypes.reduce(
  (acc, filter) => ({ ...acc, [filter]: undefined }),
  {}
)

export const emptySort: UsersSortInfo = {
  order_by: undefined,
  order_by_direction: undefined,
}

export default {
  emptyFilter,
  emptySort,
}
