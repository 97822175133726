import React, { useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'

import useStateProp from '@hooks/useStateProp'
import { createUniqueId } from '@services/dom'
import { mergeRefs } from '@services'

import styles from './styles.module.scss'

interface Props
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  numberOfLines?: number
  label?: React.ReactNode
  labelClassName?: string
  labelAsterisk?: boolean
  error?: React.ReactNode
  errorClassName?: string
  disableError?: boolean
  containerClassName?: string
  containerRef?: React.Ref<HTMLDivElement>
  textareaContainerClassName?: string
  textareaContainerRef?: React.Ref<HTMLDivElement>

  left?: React.ReactNode
  right?: React.ReactNode
}

const Textarea = (
  {
    numberOfLines,
    label,
    labelClassName,
    labelAsterisk,
    error,
    errorClassName,
    disableError,
    containerClassName,
    containerRef,
    textareaContainerClassName,
    textareaContainerRef,
    left,
    right,
    ...props
  }: Props,
  ref: React.Ref<HTMLTextAreaElement>
) => {
  const [id, setId] = useStateProp(props.id)
  const localRef = useRef<HTMLTextAreaElement>(null)
  const height = useRef<number | undefined>(undefined)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    const onFocus = () => setIsFocused(true)
    const onBlur = () => setIsFocused(false)
    localRef.current?.addEventListener('focus', onFocus)
    localRef.current?.addEventListener('blur', onBlur)

    return () => {
      localRef.current?.removeEventListener('focus', onFocus)
      localRef.current?.removeEventListener('blur', onBlur)
    }
  }, [localRef.current])

  useEffect(() => {
    if (!localRef.current || numberOfLines === undefined) {
      height.current = undefined
    } else {
      height.current =
        numberOfLines *
        Number(
          window
            .getComputedStyle(localRef.current)
            .lineHeight.replace(/\D/g, '')
        )
    }
  }, [localRef.current, height, numberOfLines])

  useEffect(() => {
    if (!id) {
      setId(createUniqueId())
    }
  }, [id])

  const errorNode = useMemo(() => {
    if (disableError) {
      return null
    }
    if (error === null) {
      error = undefined
    }

    const errorType = typeof error
    switch (errorType) {
      case 'string':
      case 'number': {
        return <p className={clsx('error-text', errorClassName)}>{error}</p>
      }
      case 'undefined':
      case 'boolean': {
        return <div className="error-placeholder" />
      }
      default: {
        return error
      }
    }
  }, [error, errorClassName, disableError])

  const labelNode = useMemo(
    () =>
      label && (
        <label htmlFor={id} className={clsx('input-label', labelClassName)}>
          {label}
          {labelAsterisk ? <span className="asterisk" /> : null}
        </label>
      ),
    [label, labelClassName, labelAsterisk, id]
  )

  const onContainerClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => event.currentTarget === event.target && localRef.current?.focus()

  return (
    <div
      className={clsx(
        'textarea-container',
        styles['textarea-wrapper'],
        containerClassName
      )}
      ref={containerRef}
    >
      {labelNode}
      <div
        className={clsx(
          styles['textarea-container'],
          isFocused && styles['focus'],
          props.disabled && styles['disabled'],
          error && styles['error'],
          textareaContainerClassName
        )}
        ref={textareaContainerRef}
        onClick={onContainerClick}
      >
        {left}
        <textarea
          {...props}
          id={id}
          ref={mergeRefs(ref, localRef)}
          className={clsx(
            'textarea',
            styles['textarea'],
            props.className,
            error && 'error'
          )}
          style={{
            ...props.style,
            height: height.current || props.style?.height,
          }}
        />
        {right}
      </div>
      {errorNode}
    </div>
  )
}

export default React.forwardRef(Textarea)
