enum CaseStatusType {
  waitingForQuiz = 'waiting for quiz',
  inQueue = 'in queue',
  closed = 'closed',
  pending = 'pending',
  processingMotion = 'processing_motion',
  processingHearing = 'processing_hearing',
  processedWaitingOnNewHearingDate = 'processed_waiting_on_new_hearing_date',
  processedWaitingOnResolving = 'processed_waiting_on_resolving',
  rescheduled = 'rescheduled',
  pendingHearing = 'pending_hearing',
  pendingMotion = 'pending_motion',
  pendingQuiz = 'pending_quiz',
  notHired = 'not_hired',
}

enum CaseStatusTypeKey {
  pending_hearing = 1,
  processing_motion = 2,
  processed_waiting_for_new_hearing_date = 3,
  processed_waiting_on_resolving = 4,
  closed = 5,
  pending_motion = 6,
  processing_hearing = 7,
  pending_quiz = 8,
  not_hired = 9,
}

const CaseStatusKeyToType = {
  [CaseStatusTypeKey.pending_hearing]: CaseStatusType.pendingHearing,
  [CaseStatusTypeKey.pending_motion]: CaseStatusType.pendingMotion,
  [CaseStatusTypeKey.processing_motion]: CaseStatusType.processingMotion,
  [CaseStatusTypeKey.processed_waiting_for_new_hearing_date]:
    CaseStatusType.processedWaitingOnNewHearingDate,
  [CaseStatusTypeKey.processed_waiting_on_resolving]:
    CaseStatusType.processedWaitingOnResolving,
  [CaseStatusTypeKey.processing_hearing]: CaseStatusType.processingHearing,
  [CaseStatusTypeKey.pending_quiz]: CaseStatusType.pendingQuiz,
  [CaseStatusTypeKey.not_hired]: CaseStatusType.notHired,
  [CaseStatusTypeKey.closed]: CaseStatusType.closed,
}

const CaseStatusLabel = {
  [CaseStatusType.waitingForQuiz]: 'waiting for quiz',
  [CaseStatusType.inQueue]: 'in queue',
  [CaseStatusType.closed]: 'closed',
  [CaseStatusType.pending]: 'pending',
  [CaseStatusType.processingMotion]: 'processing motion',
  [CaseStatusType.processingHearing]: 'processing hearing',
  [CaseStatusType.processedWaitingOnNewHearingDate]:
    'processed waiting for new hearing date',
  [CaseStatusType.processedWaitingOnResolving]:
    'processed waiting on resolving',
  [CaseStatusType.rescheduled]: 'rescheduled',
  [CaseStatusType.pendingHearing]: 'pending hearing',
  [CaseStatusType.pendingMotion]: 'pending motion',
  [CaseStatusType.pendingQuiz]: 'pending quiz',
  [CaseStatusType.notHired]: 'not hired',
}

const CaseStatusClassName = {
  [CaseStatusType.pendingQuiz]: 'waiting-for-quiz',
  [CaseStatusType.inQueue]: 'in-queue',
  [CaseStatusType.closed]: 'closed',
  [CaseStatusType.notHired]: 'closed',
  [CaseStatusType.pending]: 'pending',
  [CaseStatusType.processingMotion]: 'processing',
  [CaseStatusType.processingHearing]: 'processing',
  [CaseStatusType.processedWaitingOnNewHearingDate]: 'processing',
  [CaseStatusType.processedWaitingOnResolving]: 'processing',
  [CaseStatusType.rescheduled]: 'rescheduled',
  [CaseStatusType.pendingMotion]: 'waiting-for-quiz',
  [CaseStatusType.pendingHearing]: 'waiting-for-quiz',
  [CaseStatusType.waitingForQuiz]: 'waiting-for-quiz',
}

const caseStatusTypes = [
  CaseStatusType.waitingForQuiz,
  CaseStatusType.inQueue,
  CaseStatusType.closed,
  CaseStatusType.pendingHearing,
  CaseStatusType.pendingMotion,
  CaseStatusType.processingMotion,
  CaseStatusType.processingHearing,
  CaseStatusType.notHired,
  CaseStatusType.rescheduled,
]

export {
  CaseStatusTypeKey,
  CaseStatusKeyToType,
  CaseStatusLabel,
  CaseStatusClassName,
  caseStatusTypes,
}

export default CaseStatusType
