import React, { useCallback } from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import clsx from 'clsx'

import Input from '@components/Input'
import Select, { ValueType } from '@components/Select'

import statesOptions from '@constants/statesOptions'
import FormData from './form'
import { phoneMask } from '@constants/mask'

import sharedStyles from '../styles.module.scss'
import styles from './styles.module.scss'

interface Props {
  form: FormData
  errors: FormikErrors<FormData>
  touched: FormikTouched<FormData>
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  setFieldValue: <F extends keyof FormData = keyof FormData>(
    field: F,
    value: FormData[F],
    shouldValidate?: boolean
  ) => void
}

const RespondentInfo: React.FC<Props> = ({
  form,
  errors,
  touched,
  onChange,
  setFieldValue,
}) => {
  const onStateChange = useCallback(
    (e: ValueType<State>) => setFieldValue('respondents_state', e, false),
    [setFieldValue]
  )

  return (
    <React.Fragment>
      <div className={sharedStyles['section']}>
        <div
          className={clsx(sharedStyles['inputs'], sharedStyles['two-columns'])}
        >
          <Input
            type="text"
            name="summons_number"
            value={form.summons_number}
            onChange={onChange}
            label="Summons Number"
            labelAsterisk={true}
            placeholder="000 000 000"
            required={true}
            error={touched.summons_number && errors.summons_number}
          />
        </div>
      </div>
      <div
        className={clsx(sharedStyles['section'], styles['respondent-section'])}
      >
        <div className={sharedStyles['section-head']}>
          <h2
            className={clsx(
              sharedStyles['section-title'],
              styles['section-title']
            )}
          >
            {'Respondent Name'}
          </h2>
          <p className={sharedStyles['section-description']}>
            {'(If the respondent is an entity, business or corporation, ' +
              'please enter the full name exactly as it is written on the top of the ' +
              'summons using both boxes below. For example, First Name: ABC, Last Name: Corp.)'}
          </p>
        </div>

        <div
          className={clsx(sharedStyles['inputs'], sharedStyles['two-columns'])}
        >
          <Input
            type="text"
            name="respondents_first_name"
            value={form.respondents_first_name}
            onChange={onChange}
            label="Respondent's First Name"
            labelAsterisk={true}
            placeholder="e.g. John"
            required={true}
            error={
              touched.respondents_first_name && errors.respondents_first_name
            }
          />
          <Input
            type="text"
            name="respondents_last_name"
            value={form.respondents_last_name}
            onChange={onChange}
            label="Respondent's Last Name"
            labelAsterisk={true}
            placeholder="e.g. Millere"
            required={true}
            error={
              touched.respondents_last_name && errors.respondents_last_name
            }
          />
        </div>
      </div>

      <div className={sharedStyles['section']}>
        <div className={sharedStyles['section-head']}>
          <h2
            className={clsx(
              sharedStyles['section-title'],
              styles['section-title']
            )}
          >
            {"Respondent's Current Mailing Address"}
          </h2>
          <p className={sharedStyles['section-description']}>
            {'(If no valid mailing address is included, this e-form will ' +
              'be invalid and will not be considered by a hearing officer.)'}
          </p>
        </div>
        <div
          className={clsx(sharedStyles['inputs'], sharedStyles['two-columns'])}
        >
          <Input
            type="text"
            name="respondents_address"
            value={form.respondents_address}
            onChange={onChange}
            label="Address"
            labelAsterisk={true}
            placeholder="Enter Address"
            required={true}
            error={touched.respondents_address && errors.respondents_address}
          />
          <Input
            type="text"
            name="respondents_city"
            value={form.respondents_city}
            onChange={onChange}
            label="City"
            labelAsterisk={true}
            placeholder="Enter City"
            required={true}
            error={touched.respondents_city && errors.respondents_city}
          />
          <Select
            value={form.respondents_state}
            onChange={onStateChange}
            options={statesOptions}
            label="State"
            placeholder="Enter State"
            labelAsterisk={true}
            required={true}
            error={touched.respondents_state && errors.respondents_state}
          />
          <Input
            type="number"
            name="respondents_zip_code"
            value={form.respondents_zip_code}
            onChange={onChange}
            label="ZIP Code"
            labelAsterisk={true}
            placeholder="0000"
            required={true}
            error={touched.respondents_zip_code && errors.respondents_zip_code}
          />
          <Input
            type="text"
            name="respondents_phone"
            value={form.respondents_phone}
            onChange={onChange}
            label="Phone"
            placeholder="e.g. +1 (780) 555-1721"
            mask={phoneMask}
          />
          <Input
            type="text"
            name="respondents_email"
            value={form.respondents_email}
            onChange={onChange}
            label="Email"
            placeholder="example@email.com"
            error={touched.respondents_email && errors.respondents_email}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default RespondentInfo
