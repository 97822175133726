import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import links from '@routes/links'

type Props = RouteComponentProps

const Home: React.FC<Props> = () => <Redirect to={links.violations()} />

export default Home
