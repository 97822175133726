enum ActionType {
  LOADING = '[payments]: loading',
  ERROR = '[payments]: error',
  RESET = '[payments]: reset',

  LOAD_BEGIN = '[payments]: get begin',
  LOAD_SUCCESS = '[payments]: get success',
  LOAD_ERROR = '[payments]: get error',

  LOAD_STATUSES_BEGIN = '[payments]: get statuses begin',
  LOAD_STATUSES_SUCCESS = '[payments]: get statuses success',
  LOAD_STATUSES_ERROR = '[payments]: get statuses error',

  LOAD_INCOME_STATS_BEGIN = '[payments]: get income stats begin',
  LOAD_INCOME_STATS_SUCCESS = '[payments]: get income stats success',
  LOAD_INCOME_STATS_ERROR = '[payments]: get income stats error',

  LOAD_HALF_YEAR_INCOME_STATS_BEGIN = '[payments]: get half year income stats begin',
  LOAD_HALF_YEAR_INCOME_STATS_SUCCESS = '[payments]: get half year income stats success',
  LOAD_HALF_YEAR_INCOME_STATS_ERROR = '[payments]: get half year income stats error',
}

type LoadingAction = ReduxAction<ActionType.LOADING, boolean>

type ErrorAction = ReduxAction<ActionType.ERROR, ErrorType>

type ResetAction = ReduxAction<ActionType.RESET>

type LoadBeginAction = ReduxAction<ActionType.LOAD_BEGIN>

type LoadSuccessAction = ReduxAction<
  ActionType.LOAD_SUCCESS,
  {
    payments: Payment[]
    params: PaymentsParams
  }
>

type LoadErrorAction = ReduxAction<ActionType.LOAD_ERROR, ErrorType>

type LoadStatusesBeginAction = ReduxAction<ActionType.LOAD_STATUSES_BEGIN>

type LoadStatusesSuccessAction = ReduxAction<
  ActionType.LOAD_STATUSES_SUCCESS,
  PaymentStatusTypeKey[]
>

type LoadStatusesErrorAction = ReduxAction<
  ActionType.LOAD_STATUSES_ERROR,
  ErrorType
>

type LoadIncomeStatsBeginAction = ReduxAction<
  ActionType.LOAD_INCOME_STATS_BEGIN
>

type LoadIncomeStatsSuccessAction = ReduxAction<
  ActionType.LOAD_INCOME_STATS_SUCCESS,
  IncomeStats
>

type LoadIncomeStatsErrorAction = ReduxAction<
  ActionType.LOAD_INCOME_STATS_ERROR,
  ErrorType
>

type LoadHalfYearIncomeStatsBeginAction = ReduxAction<
  ActionType.LOAD_HALF_YEAR_INCOME_STATS_BEGIN
>

type LoadHalfYearIncomeStatsSuccessAction = ReduxAction<
  ActionType.LOAD_HALF_YEAR_INCOME_STATS_SUCCESS,
  HalfYearStats
>

type LoadHalfYearIncomeStatsErrorAction = ReduxAction<
  ActionType.LOAD_HALF_YEAR_INCOME_STATS_ERROR,
  ErrorType
>

export type Action =
  | LoadingAction
  | ErrorAction
  | ResetAction
  | LoadBeginAction
  | LoadSuccessAction
  | LoadErrorAction
  | LoadStatusesBeginAction
  | LoadStatusesSuccessAction
  | LoadStatusesErrorAction
  | LoadIncomeStatsBeginAction
  | LoadIncomeStatsSuccessAction
  | LoadIncomeStatsErrorAction
  | LoadHalfYearIncomeStatsBeginAction
  | LoadHalfYearIncomeStatsSuccessAction
  | LoadHalfYearIncomeStatsErrorAction

export default ActionType
