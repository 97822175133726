import ActionType, { Action } from './types'

import { defaultPerPage } from '@config'

export interface IState {
  isLoading: boolean
  users: User[]
  params: UsersParams

  user: User | null

  error: ErrorType | null
}

const initialState: IState = {
  isLoading: false,
  users: [],
  params: {
    page: 0,
    per_page: defaultPerPage,
    totalPages: 0,
  },

  user: null,

  error: null,
}

const reducer = (state = initialState, action: Action): IState => {
  switch (action.type) {
    case ActionType.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }
    case ActionType.RESET: {
      return {
        ...initialState,
      }
    }
    case ActionType.LOAD_BEGIN: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case ActionType.LOAD_DETAILS_BEGIN: {
      return {
        ...state,
        isLoading: true,
        user: null,
      }
    }
    case ActionType.LOAD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        users: action.payload.users,
        params: {
          ...state.params,
          ...action.payload.params,
        },
      }
    }
    case ActionType.LOAD_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      }
    }
    case ActionType.ERROR:
    case ActionType.LOAD_ERROR:
    case ActionType.LOAD_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default reducer
