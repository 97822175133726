import React, { useMemo, useState } from 'react'
import { LinkProps } from 'react-router-dom'
import clsx from 'clsx'

import Link from '@components/Link'
import Modal from '@components/Modal'

import Avatar from '@assets/default-avatar.png'

import styles from './styles.module.scss'

interface Props {
  className?: string
  user: WebUser
  links: LinkProps[]
  logout: () => void
}

const Drawer: React.FC<Props> = ({
  className,
  user: { firstName, lastName },
  links,
  logout,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen((prev) => !prev)

  const siteLinks = useMemo(
    () =>
      links.map(({ className, children, ...link }, index) => {
        const pathName = `/${window.location.pathname.split('/')[1]}` || '/'
        return (
          <li key={`${link.to}-${index}`} className={styles['link-item']}>
            <Link
              {...link}
              className={clsx(
                'link',
                className,
                styles['link'],
                link.to &&
                  typeof link.to === 'string' &&
                  link.to.startsWith(pathName) &&
                  styles['active-link']
              )}
            >
              {children}
            </Link>
          </li>
        )
      }),
    [window.location.pathname, links]
  )

  return (
    <React.Fragment>
      <button
        className={clsx(
          styles['drawer-button'],
          isOpen && styles['drawer-button-open'],
          className
        )}
        onClick={toggle}
      >
        <span />
        <span />
        <span />
      </button>
      <Modal
        coverScreen={true}
        open={isOpen}
        className={clsx(styles['drawer'])}
        wrapperClassName={clsx(
          styles['drawer-container'],
          isOpen && styles['drawer-container-open']
        )}
      >
        <div className={styles['account']}>
          <img src={Avatar} className={styles['avatar']} />
          <p className={styles['user-name']}>{`${firstName} ${lastName}`}</p>
          {
            // todo: add user role from user object
          }
          <p className={styles['user-role']}>{'Super Admin'}</p>
        </div>
        <ul className={styles['links-container']}>{siteLinks}</ul>
        <button onClick={() => logout()} className={styles['logout']}>
          {'Log out'}
        </button>
      </Modal>
    </React.Fragment>
  )
}

export default Drawer
