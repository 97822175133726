import ActionType from '@redux/violations/types'

const preprocessInfractionCodes: ReduxThunkMiddleware = (_) => (next) => (
  action: AnyAction
) => {
  if (action.type === ActionType.LOAD_INF_CODES_SUCCESS) {
    action.payload.infCodes = action.payload.infCodes.filter(Boolean)
  }
  return next(action)
}

const preprocessHearingResults: ReduxThunkMiddleware = (_) => (next) => (
  action: AnyAction
) => {
  if (action.type === ActionType.LOAD_HEARING_RESULTS_SUCCESS) {
    action.payload = action.payload.filter(Boolean)
  }
  return next(action)
}

const preprocessCaseStatuses: ReduxThunkMiddleware = (_) => (next) => (
  action: AnyAction
) => {
  if (action.type === ActionType.LOAD_CASE_STATUSES_SUCCESS) {
    action.payload = action.payload.filter(Boolean)
  }
  return next(action)
}

const violationMiddlewares = [
  preprocessInfractionCodes,
  preprocessHearingResults,
  preprocessCaseStatuses,
]

export default violationMiddlewares
