import clsx from 'clsx'
import React from 'react'

import styles from './styles.module.scss'

interface Props<TabValue extends string> {
  tabs: TabValue[]
  tab: TabValue
  setTab: (tab: TabValue) => void
  cancel: () => void
  className?: string
  submitDisabled?: boolean
}

const Footer = <TabValue extends string>({
  tabs,
  tab,
  setTab,
  cancel,
  className,
  submitDisabled,
}: Props<TabValue>) => {
  const index = tabs.indexOf(tab)
  const isFirst = index < 1
  const isLast = index >= tabs.length - 1

  const prev = () => setTab(tabs[index - 1])
  const next = () => setTab(tabs[index + 1])

  return (
    <footer className={clsx(styles['form-footer'], className)}>
      <button
        className={clsx(
          'secondary-button',
          styles['footer-button'],
          styles['footer-button-secondary'],
          isFirst && styles['footer-button-show']
        )}
        type="button"
        onClick={cancel}
      >
        {'Cancel'}
      </button>
      <button
        className={clsx(
          'secondary-button',
          styles['footer-button'],
          styles['footer-button-secondary'],
          !isFirst && styles['footer-button-show']
        )}
        type="button"
        onClick={prev}
      >
        {'Back'}
      </button>
      <button
        className={clsx(
          'primary-button',
          styles['footer-button'],
          styles['footer-button-primary'],
          !isLast && styles['footer-button-show']
        )}
        type="button"
        onClick={next}
      >
        {'Next'}
      </button>
      <button
        className={clsx(
          'primary-button',
          styles['footer-button'],
          styles['footer-button-primary'],
          isLast && styles['footer-button-show']
        )}
        type="submit"
        disabled={submitDisabled}
      >
        {'Submit'}
      </button>
    </footer>
  )
}

export default Footer
