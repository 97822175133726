import { useEffect, useLayoutEffect, useRef, useState } from 'react'

const useStateWithCb = <State = never, Deps = unknown[]>(
  initialState: State | (() => State),
  callback: (state: State) => void,
  deps: Deps[] = []
): [State, React.Dispatch<React.SetStateAction<State>>] => {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    callback(state)
  }, [state, ...deps])

  return [state, setState]
}

const useStateWithCbInstant = <State = never, Deps = unknown[]>(
  initialState: State,
  callback: (state: State) => void,
  deps: Deps[] = []
): [State, React.Dispatch<React.SetStateAction<State>>] => {
  const [state, setState] = useState(initialState)

  useLayoutEffect(() => callback(state), [state, callback, ...deps])

  return [state, setState]
}

const useStateWithSetCb = <State = never>(
  initialState: State
): [State, (_: State, cb?: (_: State) => void) => void] => {
  const [state, setState] = useState(initialState)
  const callbackRef = useRef<((state: State) => void) | undefined>(undefined)

  useEffect(() => callbackRef.current?.(state), [state])

  const set = (newState: State, callback?: (state: State) => void) => {
    callbackRef.current = callback
    setState(newState)
  }

  return [state, set]
}

export { useStateWithCbInstant, useStateWithSetCb }

export default useStateWithCb
