import React, { useMemo } from 'react'
import clsx from 'clsx'

import Header from '@common/Header'

import FormCard from './cards/FormCard'
import CompleteCard from './cards/CompleteCard'
import { FormData } from './cards/FormCard/form'

import styles from './styles.module.scss'

export enum PageState {
  form = 'form',
  complete = 'complete',
}

interface Props {
  isLoading: boolean
  pageState: PageState
  onSubmit: (values: FormData) => void
}

const View: React.FC<Props> = ({ isLoading, pageState, onSubmit }) => {
  const card = useMemo(() => {
    switch (pageState) {
      case PageState.form: {
        return <FormCard isLoading={isLoading} onSubmit={onSubmit} />
      }
      case PageState.complete: {
        return <CompleteCard />
      }
      default: {
        return null
      }
    }
  }, [pageState])

  return (
    <div className={clsx('page', styles['forgot-password-page'])}>
      <Header />
      <div
        className={clsx('page-content', styles['forgot-password-page-content'])}
      >
        {card}
        <p className={clsx('additional-text', styles['footer-text'])}>
          {
            'If you do not have login information, please check you email or contact administrator'
          }
        </p>
      </div>
    </div>
  )
}

export default View
