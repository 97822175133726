import React from 'react'
import clsx from 'clsx'

import Header from '@common/Header'

import Charts from './Charts'
import TableSection from './TableSection'

import styles from './styles.module.scss'

const View: React.FC = () => {
  return (
    <div className={clsx('page', styles['users'])}>
      <Header />
      <div className={clsx('page-content', styles['page-content'])}>
        <Charts />
        <TableSection />
      </div>
    </div>
  )
}

export default View
