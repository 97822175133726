import React, { useCallback, useEffect, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'
import clsx from 'clsx'
import { ValueType } from 'react-select'
import { useFormik } from 'formik'

import FilterModal from '@common/FilterModal'
import Select, {
  OptionType,
  getMultiSelectValue,
  getSelectValue,
} from '@components/Select'
import Status from '@components/Status'
import BigCheckbox from '@components/BigCheckbox'
import CalendarInput from '@components/CalendarInput'
import CaseStatus from '@components/CaseStatus'

import violationsActions from '@redux/violations/actions'
import paymentsActions from '@redux/payments/actions'
import buildingsActions from '@redux/buildings/actions'
import { emptyFilter as emptyBuildingsFilter } from '@services/building'
import { emptyInfCodesFilter } from '@services/violation'
import { apiDate } from '@services/date'

import {
  BalanceType,
  CaseStatusKeyToType,
  HearingStatusLabel,
  PaidStatusType,
  ViolationGroupType,
} from '@typings/enums'

import styles from './styles.module.scss'

const mapStateToProps = (state: ReduxState) => ({
  buildings: state.buildings.buildings,
  buildingsSearch: state.buildings.params.search,
  caseStatusKeys: state.violations.caseStatusKeys,
  infCodes: state.violations.infCodes,
  infCodesSearch: state.violations.infCodesParams.search,
  hearingResults: state.violations.hearingResults,
  isLoading: state.payments.isLoading,
})

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  paymentsActions: bindActionCreators<
    typeof paymentsActions,
    BindedAsyncActions<typeof paymentsActions>
  >(paymentsActions, dispatch),
  buildingsActions: bindActionCreators<
    typeof buildingsActions,
    BindedAsyncActions<typeof buildingsActions>
  >(buildingsActions, dispatch),
  violationsActions: bindActionCreators<
    typeof violationsActions,
    BindedAsyncActions<typeof violationsActions>
  >(violationsActions, dispatch),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

interface NativeProps {
  open: boolean
  close: () => void
  groupBy: ViolationGroupType
}

type Props = NativeProps & ConnectedProps<typeof connector>

interface FormData {
  buildings: ValueType<OptionType>
  listOfCases: ValueType<OptionType>
  infractionCodes: ValueType<OptionType>
  resultReceived: Date[] | undefined
  hearingStatus: ValueType<OptionType>
  paymentStatus: ValueType<OptionType<StrBoolean>>
  feeType: boolean
  fineType: boolean
}

const formInitial: FormData = {
  buildings: [],
  listOfCases: [],
  infractionCodes: [],
  resultReceived: undefined,
  hearingStatus: [],
  paymentStatus: undefined,
  feeType: true,
  fineType: true,
}

const paymentStatusTypeOptions: OptionType[] = [
  {
    value: '1',
    label: <Status type={PaidStatusType.paid} />,
  },
  {
    value: '0',
    label: <Status type={PaidStatusType.unpaid} />,
  },
]

const Filters: React.FC<Props> = ({
  open,
  close,
  groupBy,
  buildings = [],
  buildingsSearch,
  caseStatusKeys = [],
  infCodes = [],
  infCodesSearch,
  hearingResults = [],
  isLoading,
  buildingsActions: { load: loadBuildings },
  violationsActions: {
    loadCaseStatuses,
    loadInfractionCodes,
    loadHearingResults,
    filter,
    resetFilters,
  },
}) => {
  const {
    values,
    setFieldValue,
    handleSubmit,
    handleReset,
    handleChange,
    setValues,
  } = useFormik<FormData>({
    initialValues: {
      ...formInitial,
    },
    onSubmit: ({
      buildings,
      listOfCases,
      infractionCodes,
      resultReceived,
      hearingStatus,
      paymentStatus,
      feeType,
      fineType,
    }) => {
      filter({
        buildings_ids: getMultiSelectValue(buildings),
        infraction_codes: getMultiSelectValue(infractionCodes),
        hearing_date_start:
          resultReceived && resultReceived[0] && apiDate(resultReceived[0]),
        hearing_date_end:
          resultReceived && resultReceived[1] && apiDate(resultReceived[1]),
        hearing_results: getMultiSelectValue(hearingStatus),
        case_statuses: getMultiSelectValue(listOfCases),
        payment_status: getSelectValue(paymentStatus),
        payment_type:
          feeType === fineType
            ? undefined
            : feeType
            ? BalanceType.fee
            : fineType
            ? BalanceType.fine
            : undefined,
      })
    },
    onReset: (_values, { setValues }) => {
      resetFilters(() => setValues({ ...formInitial }))
    },
  })

  useEffect(() => {
    if (groupBy !== ViolationGroupType.hearingDate) {
      setValues({
        ...formInitial,
        buildings: values.buildings,
        listOfCases: values.listOfCases,
        infractionCodes: values.infractionCodes,
      })
    }
  }, [groupBy])

  useEffect(() => {
    loadBuildings({ page: -1, ...emptyBuildingsFilter })
  }, [loadBuildings])

  useEffect(() => {
    loadCaseStatuses()
  }, [loadCaseStatuses])

  useEffect(() => {
    loadInfractionCodes({ ...emptyInfCodesFilter })
  }, [loadInfractionCodes])

  useEffect(() => {
    loadHearingResults()
  }, [loadHearingResults])

  const buildingsOptions: OptionType[] = useMemo(
    () =>
      buildings.map((building) => ({
        value: String(building.id),
        label: building.address,
      })),
    [buildings]
  )

  const listOfCasesOptions = useMemo(
    () =>
      caseStatusKeys.map((value) => ({
        value: String(value),
        label: <CaseStatus type={CaseStatusKeyToType[value] || value} />,
      })),
    [caseStatusKeys]
  )

  const infractionCodesOptions = useMemo(
    () => infCodes.map((value) => ({ value: value, label: value })),
    [infCodes]
  )

  const hearingStatusOptions = useMemo(
    () =>
      hearingResults.map((value) => ({
        value,
        label: HearingStatusLabel[value] || value,
      })),
    [hearingResults]
  )

  const onBuildingsChange = useCallback(
    (e: ValueType<OptionType>) => setFieldValue('buildings', e, false),
    [setFieldValue]
  )

  const onBuildingsInputChange = useCallback(
    (newValue: string) => {
      if (!newValue && !buildingsSearch) {
        return
      }
      if (newValue !== buildingsSearch) {
        loadBuildings({ search: newValue || undefined })
      }
    },
    [setFieldValue, loadBuildings, buildingsSearch]
  )

  const onListOfCasesChange = useCallback(
    (e: ValueType<OptionType>) => setFieldValue('listOfCases', e, false),
    [setFieldValue]
  )

  const onInfractionCodesChange = useCallback(
    (e: ValueType<OptionType>) => setFieldValue('infractionCodes', e, false),
    [infractionCodesOptions, setFieldValue]
  )

  const onInfractionCodesInputChange = useCallback(
    (newValue: string) => {
      if (!newValue && !infCodesSearch) {
        return
      }
      if (newValue !== infCodesSearch) {
        loadInfractionCodes({ search: newValue || undefined })
      }
    },
    [setFieldValue, loadBuildings, infCodesSearch]
  )

  const onResultReceivedChange = useCallback(
    (dates: Date[]) => setFieldValue('resultReceived', dates, false),
    [setFieldValue]
  )

  const onHearingStatusChange = useCallback(
    (e: ValueType<OptionType>) => setFieldValue('hearingStatus', e, false),
    [setFieldValue]
  )

  const onPaymentStatusChange = useCallback(
    (e: ValueType<OptionType>) => setFieldValue('paymentStatus', e, false),
    [setFieldValue]
  )

  const additionalFilters = useMemo(() => {
    switch (groupBy) {
      case ViolationGroupType.hearingDate: {
        return (
          <React.Fragment>
            <CalendarInput
              options={{ mode: 'range' }}
              placeholder="All"
              value={values.resultReceived}
              onChange={onResultReceivedChange}
              label="Result received"
              labelClassName={styles['select-label']}
              containerClassName={styles['select-container']}
            />

            <Select
              isMulti={true}
              value={values.hearingStatus}
              options={hearingStatusOptions}
              onChange={onHearingStatusChange}
              placeholder="All"
              label="Hearing status"
              labelClassName={styles['select-label']}
              containerClassName={styles['select-container']}
            />

            <Select
              isMulti={false}
              value={values.paymentStatus}
              options={paymentStatusTypeOptions}
              onChange={onPaymentStatusChange}
              className={clsx(styles['select'], styles['status-select'])}
              placeholder="All"
              label="Payment Status"
              labelClassName={styles['select-label']}
              containerClassName={styles['select-container']}
            />

            <div
              className={clsx(
                styles['select-container'],
                styles['select-with-margin']
              )}
            >
              <label className={clsx('input-label', styles['select-label'])}>
                {'Payment Type'}
              </label>
              <div className={styles['payment-types']}>
                <BigCheckbox
                  name="feeType"
                  checked={values.feeType}
                  onChange={handleChange}
                  containerClassName={styles['payment-type']}
                >
                  {'Fee'}
                </BigCheckbox>
                <BigCheckbox
                  name="fineType"
                  checked={values.fineType}
                  onChange={handleChange}
                  containerClassName={styles['payment-type']}
                >
                  {'Fine'}
                </BigCheckbox>
              </div>
            </div>
          </React.Fragment>
        )
      }
      default: {
        return null
      }
    }
  }, [groupBy, values])

  return (
    <FilterModal
      open={open}
      close={close}
      formProps={{ onSubmit: handleSubmit, onReset: handleReset }}
      isLoading={isLoading}
    >
      <Select
        isMulti={true}
        value={values.buildings}
        options={buildingsOptions}
        onChange={onBuildingsChange}
        className={clsx(styles['select'], styles['building-select'])}
        placeholder="All"
        onInputChange={onBuildingsInputChange}
        label="Building"
        labelClassName={styles['select-label']}
        containerClassName={styles['select-container']}
      />

      <Select
        isMulti={true}
        value={values.listOfCases}
        options={listOfCasesOptions}
        onChange={onListOfCasesChange}
        placeholder="All"
        label="List of cases"
        labelClassName={styles['select-label']}
        containerClassName={styles['select-container']}
      />

      <Select
        isMulti={true}
        value={values.infractionCodes}
        options={infractionCodesOptions}
        onChange={onInfractionCodesChange}
        placeholder="All"
        onInputChange={onInfractionCodesInputChange}
        label="Infraction code"
        labelClassName={styles['select-label']}
        containerClassName={styles['select-container']}
      />

      {additionalFilters}
    </FilterModal>
  )
}

export default connector(Filters) as React.FC<NativeProps>
