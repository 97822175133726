import React from 'react'
import clsx from 'clsx'

import Status from '@components/Status'

import { formatAmount } from '@services/money'
import { getAmountType, getStatusType } from '@services/payment'

import styles from './styles.module.scss'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > {
  payment: Payment
}

const PaymentRow: React.FC<Props> = ({ payment, className, ...props }) => (
  <tr {...props} className={clsx('tr', styles['row'], className)}>
    <td>
      <p className={styles['id']}>{String(payment.id).padStart(5, '0')}</p>
    </td>
    <td>
      <p className={styles['name']}>{payment.name}</p>
    </td>
    <td>
      <a
        className={clsx('blue-link', styles['email'])}
        href={`mailto:${payment.email}`}
      >
        {payment.email}
      </a>
    </td>
    <td>
      <a className={styles['phone-number']} href={`tel:${payment.phone}`}>
        {payment.phone}
      </a>
    </td>
    <td>
      <p className={styles['building']} title={payment.address}>
        {payment.address}
      </p>
    </td>
    <td>
      <a className={clsx('blue-link', styles['violation'])}>
        {payment.ticketNumber}
      </a>
    </td>
    <td>
      <p className={styles['amount']}>
        {formatAmount(payment.amount)}
        <span className={styles['amount-type']}>{getAmountType(payment)}</span>
      </p>
    </td>
    <td>
      <Status className={styles['status']} type={getStatusType(payment)} />
    </td>
  </tr>
)

export default PaymentRow
