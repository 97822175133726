import { Mask } from 'text-mask-core'

export const shortPhoneMask: Mask = [
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const longPhoneMask: Mask = [
  '+',
  /[1-9]/,
  ' ',
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const phoneMask = shortPhoneMask

export default {
  phoneMask,
}
