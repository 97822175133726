import React from 'react'

import { RenderItemArg } from './types'

import styles from './styles.module.scss'
import clsx from 'clsx'

const renderItem = <T extends string>({
  item,
  index,
  activeIndex,
  setValue,
}: RenderItemArg<T>) => {
  const className =
    index < activeIndex
      ? styles['tab-item-prev']
      : index > activeIndex
      ? styles['tab-item-next']
      : styles['tab-item-active']

  const onClick = () => {
    setValue(item.value)
  }

  return (
    <button onClick={onClick} className={clsx(styles['tab-item'], className)}>
      <span className={styles['circle']}>
        <span className={styles['circle-inner']} />
      </span>
      {item.label || item.value}
    </button>
  )
}

export default renderItem
