import React, { useLayoutEffect, useMemo, useRef } from 'react'
import clsx from 'clsx'
import { Datum, Line, Serie, SliceTooltip } from '@nivo/line'

import useRem from '@hooks/useRem'
import colors from '@constants/colors'
import useDimensions from '@hooks/useDimensions'
import { addHovers } from './service'
import { createUniqueId } from '@services/dom'

import { LabelExtractor, TickProps, ValueExtractor } from './types'

import styles from './styles.module.scss'

const defaultValueExtractor: ValueExtractor = (item) =>
  typeof item === 'number' ? item : 0
const defaultLabelExtractor: LabelExtractor = (item, index) =>
  typeof item === 'string' || typeof item === 'number' ? item : index

interface Props<I> {
  data: I[]
  valueExtractor?: ValueExtractor<I>
  labelExtractor?: LabelExtractor<I>
  className?: string
  renderBottomAxis?: (value: string) => React.ReactNode
  renderLeftAxis?: (value: number) => React.ReactNode
  padding?: boolean
  min?: number
  max?: number
  tooltip?: SliceTooltip
  yAxisStep?: number
}

const LinearChart = <I extends unknown>({
  data: dataProp,
  valueExtractor = defaultValueExtractor,
  labelExtractor = defaultLabelExtractor,
  className,
  renderBottomAxis,
  renderLeftAxis,
  padding,
  min = 0,
  max = Math.max(...dataProp.map(valueExtractor)),
  tooltip,
  yAxisStep = Math.round((max - min) / 400) * 100,
}: Props<I>) => {
  const [containerRef, dimensions] = useDimensions<HTMLDivElement>(true)
  const ref = useRef<Line>(null)

  const rem = useRem()

  const data = useMemo<Serie>(() => {
    const data: Datum[] = dataProp.map((...args) => ({
      x: labelExtractor(...args),
      y: valueExtractor(...args),
    }))
    if (padding) {
      data.unshift({
        x: '‌‌ ',
        y: data[0].y,
      })
      data.push({
        x: ' ',
        y: data[data.length - 1].y,
      })
    }
    return {
      data,
      id: createUniqueId(),
    }
  }, [dataProp, valueExtractor, labelExtractor, padding])

  useLayoutEffect(() => {
    if (containerRef.current) {
      return addHovers(containerRef.current, rem, styles, padding)
    }
  }, [rem, padding, dimensions, data])

  const renderBottomTick = useMemo(() => {
    if (!renderBottomAxis) {
      return undefined
    } else {
      const tick = <T extends unknown = TickProps<string>>(props: T) => {
        if (typeof props === 'object') {
          const data = (props as unknown) as TickProps<string>
          return (
            <g
              transform={`translate(${data.x + data.textX}, ${
                data.y + data.textY
              }) rotate(${data.rotate})`}
              dominantBaseline={data.textBaseline}
              textAnchor={data.textAnchor}
            >
              {renderBottomAxis(data.value)}
            </g>
          )
        } else {
          return null
        }
      }
      return tick
    }
  }, [renderBottomAxis, padding])

  const renderLeftTick = useMemo(() => {
    if (!renderLeftAxis) {
      return undefined
    } else {
      const tick = <T extends unknown = TickProps<number>>(props: T) => {
        if (typeof props === 'object') {
          const data = (props as unknown) as TickProps<number>
          return (
            <g
              transform={`translate(${data.x + data.textX}, ${
                data.y + data.textY
              }) rotate(${data.rotate})`}
              dominantBaseline={data.textBaseline}
              textAnchor={data.textAnchor}
            >
              {renderLeftAxis(data.value)}
            </g>
          )
        } else {
          return null
        }
      }
      return tick
    }
  }, [renderLeftAxis])

  const yAxisValues = useMemo(
    () =>
      yAxisStep &&
      Array(Math.floor((max - min) / yAxisStep) + 1)
        .fill(undefined)
        .map((_, index) => min + index * yAxisStep),
    [yAxisStep]
  )

  return (
    <div
      ref={containerRef}
      className={clsx(
        styles['chart-container'],
        padding && styles['with-padding'],
        className
      )}
    >
      <Line
        ref={ref}
        data={[data]}
        height={rem(dimensions.height)}
        width={rem(dimensions.width)}
        margin={{
          top: rem(40),
          bottom: rem(40),
          left: rem(40),
          right: rem(40),
        }}
        //*
        pointSize={rem(15)}
        pointBorderWidth={rem(3)}
        pointBorderColor={colors.royalBlue}
        pointColor={colors.white}
        //*
        lineWidth={rem(4)}
        //*
        isInteractive={true}
        animate={false}
        useMesh={true}
        //*
        colors={colors.royalBlue}
        //*
        enableGridX={false}
        enableGridY={true}
        enableSlices="x"
        gridYValues={yAxisValues}
        //*
        axisBottom={{
          tickSize: 0,
          renderTick: renderBottomTick,
        }}
        axisLeft={{
          tickSize: 0,
          renderTick: renderLeftTick,
          tickValues: yAxisValues,
        }}
        //*
        enableCrosshair={true}
        crosshairType="x"
        //*
        yScale={{
          type: 'linear',
          min,
          max,
        }}
        //*
        sliceTooltip={tooltip}
      />
    </div>
  )
}

export default LinearChart
