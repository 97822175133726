import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'

import { mergeRefs } from '@services'

import styles from './styles.module.scss'

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  vertical?: boolean
  horizontal?: boolean
  openedClassName?: string
  closedClassName?: string
  open: boolean
  children?: React.ReactNode
}

const Collapsable = (
  {
    vertical,
    horizontal,
    className,
    openedClassName,
    closedClassName,
    open,
    children,
    style,
    ...props
  }: IProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const localRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)

  const update = () => {
    setHeight(localRef.current?.scrollHeight || 0)
    setWidth(localRef.current?.scrollWidth || 0)
  }

  useEffect(() => {
    update()
    window.addEventListener('resize', update)
    return () => window.removeEventListener('resize', update)
  }, [open, children])

  const setClassNames = () => {
    if (open) {
      localRef.current!.classList.add(styles.isOpen)
      openedClassName && localRef.current!.classList.add(openedClassName)
    } else {
      localRef.current!.classList.add(styles.isClosed)
      closedClassName && localRef.current!.classList.add(closedClassName)
    }
  }

  useEffect(() => {
    setClassNames()
  }, [])

  useEffect(() => {
    if (localRef.current) {
      const ontransitionstart = () => {
        update()
      }
      const ontransitionend = () => {
        setClassNames()
      }
      const ontransitionrun = () => {
        localRef.current!.classList.remove(styles['is-open'])
        localRef.current!.classList.remove(styles['is-closed'])
        openedClassName && localRef.current!.classList.remove(openedClassName)
        closedClassName && localRef.current!.classList.remove(closedClassName)
      }
      localRef.current.addEventListener('transitionstart', ontransitionstart)
      localRef.current.addEventListener('transitionend', ontransitionend)
      localRef.current.addEventListener('transitionrun', ontransitionrun)
      return () => {
        localRef.current!.removeEventListener(
          'transitionstart',
          ontransitionstart
        )
        localRef.current!.removeEventListener('transitionend', ontransitionend)
        localRef.current!.removeEventListener('transitionrun', ontransitionrun)
      }
    }
  }, [localRef.current, open, children])
  return (
    <div
      {...props}
      ref={mergeRefs(ref, localRef)}
      className={clsx(styles['collapsable'], className)}
      style={{
        ...style,
        maxHeight: vertical && open ? height : style?.maxHeight,
        maxWidth: horizontal && open ? width : style?.maxWidth,
      }}
    >
      {children}
    </div>
  )
}

export default React.forwardRef(Collapsable)
