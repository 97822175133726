import React from 'react'
import clsx from 'clsx'
import { useFormik } from 'formik'

import Input from '@components/Input'
import { minLengthValidate, validate } from '@services/validation'
import { getFormErrors } from '@services/error'
import { minPasswordLen } from '@config'

import styles from './styles.module.scss'

export interface FormData {
  password: string
  password2: string
}

interface Props {
  isLoading: boolean
  onSubmit: (values: FormData) => void
}

const passwordLenValidator = minLengthValidate(minPasswordLen)

const Form: React.FC<Props> = ({ isLoading, onSubmit }) => {
  const formik = useFormik<FormData>({
    initialValues: {
      password: '',
      password2: '',
    },
    validateOnBlur: true,
    validate: async ({ password, password2 }) =>
      getFormErrors({
        password: validate(password, passwordLenValidator),
        password2:
          password2 !== password ? 'Password have to match' : undefined,
      }),
    onSubmit,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        name="password"
        id="password"
        type="password"
        placeholder="6+ characters"
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        required={true}
        label="New Password"
        containerClassName={styles['input-container']}
        error={formik.touched.password ? formik.errors.password : null}
      />
      <Input
        name="password2"
        id="password2"
        type="password"
        placeholder="Repeat password"
        value={formik.values.password2}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        required={true}
        label="Confirm Password"
        containerClassName={styles['input-container']}
        error={formik.touched.password2 ? formik.errors.password2 : null}
      />
      <button
        type="submit"
        className={clsx('primary-button', styles['submit-btn'])}
        disabled={isLoading || !formik.isValid}
      >
        {'Save'}
      </button>
    </form>
  )
}

export default Form
