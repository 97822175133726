import ActionType, { Action } from './types'

export interface IState {
  isLoading: boolean
  user: WebUser | null
  error: ErrorType | null
}

const initialState: IState = {
  isLoading: false,
  user: null,
  error: null,
}

const reducer = (state = initialState, action: Action): IState => {
  switch (action.type) {
    case ActionType.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }
    case ActionType.RESET: {
      return {
        ...initialState,
      }
    }
    case ActionType.LOGIN_BEGIN:
    case ActionType.LOGOUT_BEGIN:
    case ActionType.FORGOT_PASSWORD_BEGIN:
    case ActionType.RESET_PASSWORD_BEGIN: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case ActionType.LOGIN_SUCCESS:
    case ActionType.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        isLoading: false,
      }
    }
    case ActionType.LOGOUT_SUCCESS:
    case ActionType.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        user: null,
        isLoading: false,
      }
    }
    case ActionType.ERROR:
    case ActionType.LOGIN_ERROR:
    case ActionType.LOGOUT_ERROR:
    case ActionType.FORGOT_PASSWORD_ERROR:
    case ActionType.RESET_PASSWORD_ERROR: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default reducer
