import React from 'react'
import clsx from 'clsx'

import Link from '@components/Link'

import links from '@routes/links'

import PasswordSendIcon from '@assets/forgot-password-sent.png'

import styles from '../../styles.module.scss'

const CompleteCard: React.FC = () => (
  <div
    className={clsx(
      'card',
      'big-card',
      styles['forgot-password-card'],
      styles['forgot-password-complete-card']
    )}
  >
    <img
      src={PasswordSendIcon}
      className={clsx('img', 'contain', styles['email-sent-icon'])}
    />
    <p className={clsx('clack-text', styles['subtitle'])}>
      {'Please follow instruction on you email'}
    </p>
    <Link
      to={links.login()}
      className={clsx('big-blue-link', styles['login-link'])}
    >
      {'Back to Login'}
    </Link>
  </div>
)

export default CompleteCard
