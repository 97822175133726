import React, { useCallback, useMemo } from 'react'
import clsx from 'clsx'
import { ReactSVG } from 'react-svg'
import { SliceTooltip } from '@nivo/line'

import LinearChart from '@components/LinearChart'

import { formatChartValueAxis } from '@services/chart'
import { formatAmountK } from '@services/money'

import StonksUpIcon from '@assets/stonks-up.svg'

import styles from './styles.module.scss'
import {
  IncomePeriodType,
  IncomePeriodTypeLabel,
  incomePeriodTypes,
} from '@typings/enums'
import { getData } from '@services/incomeChart'
import useStateWithCb from '@hooks/useStateWithCb'

interface Props {
  incomeStats: IncomeStats
  initialPeriod?: IncomePeriodType
  onPeriodChange?: (period: IncomePeriodType) => void
  title?: string
}

const IncomeChart: React.FC<Props> = ({
  incomeStats,
  initialPeriod = IncomePeriodType.daily,
  onPeriodChange,
  title = 'Sanisaver income',
}) => {
  const periodCallback = useCallback(
    (period: IncomePeriodType) => {
      onPeriodChange?.(period)
    },
    [onPeriodChange]
  )

  const [period, setPeriod] = useStateWithCb(initialPeriod, periodCallback)

  const incomeData = useMemo(() => getData(incomeStats, period), [
    incomeStats,
    period,
  ])

  const incomeValueExtractor = (val: ChartData) => val.value
  const incomeLabelExtractor = (val: ChartData) => val.label

  const renderBottomAxis = useCallback(
    (value: string) => (
      <text y="10" className={styles['x-axis-text']}>
        {value}
      </text>
    ),
    []
  )

  const renderLeftAxis = useCallback(
    (value) => (
      <text className={styles['y-axis-text']}>
        {formatChartValueAxis(value)}
      </text>
    ),
    []
  )

  const tooltip = useCallback<SliceTooltip>(
    ({
      slice: {
        points: [point],
      },
    }) => {
      return (
        <div className={styles['tooltip']}>
          <div className={styles['tooltip-title-container']}>
            <p className={styles['tooltip-title']}>
              {formatAmountK(point?.data?.y as number)}
            </p>
            <ReactSVG src={StonksUpIcon} className={styles['stonks-up-icon']} />
          </div>
          <p className={styles['tooltip-subtitle']}>
            <span className={styles['subtitle-bold']}>
              {`${incomeData[point?.index - 1].percent.toFixed(2)}%`}
            </span>
            {' Successful cases'}
          </p>
        </div>
      )
    },
    [incomeData]
  )

  const chartTypeLinks = useMemo(
    () =>
      incomePeriodTypes.map((type) => (
        <li key={type} className={styles['chart-type-item']}>
          <a
            className={clsx(
              styles['chart-type'],
              period === type && styles['chart-type-active']
            )}
            onClick={() => setPeriod(type)}
          >
            {IncomePeriodTypeLabel[type]}
          </a>
        </li>
      )),
    [period, setPeriod]
  )

  return (
    <div
      className={clsx(
        styles['chart-container'],
        styles['linear-chart-container']
      )}
    >
      <div className={styles['chart-heading']}>
        <h2 className={styles['title']}>{title}</h2>
        <nav className={styles['navigation']}>
          <ul className={styles['links']}>{chartTypeLinks}</ul>
        </nav>
      </div>
      <LinearChart
        data={incomeData}
        valueExtractor={incomeValueExtractor}
        labelExtractor={incomeLabelExtractor}
        className={styles['linear-chart']}
        padding={true}
        renderLeftAxis={renderLeftAxis}
        renderBottomAxis={renderBottomAxis}
        tooltip={tooltip}
      />
    </div>
  )
}

export default IncomeChart
