enum ActionType {
  LOADING = '[violations]: loading',
  ERROR = '[violations]: error',
  RESET = '[violations]: reset',

  LOAD_BEGIN = '[violations]: get begin',
  LOAD_SUCCESS = '[violations]: get success',
  LOAD_ERROR = '[violations]: get error',

  LOAD_DETAILS_BEGIN = '[violations]: get details begin',
  LOAD_DETAILS_SUCCESS = '[violations]: get details success',
  LOAD_DETAILS_ERROR = '[violations]: get details error',

  UPDATE_BEGIN = '[violations]: update begin',
  UPDATE_SUCCESS = '[violations]: update success',
  UPDATE_ERROR = '[violations]: update error',

  LOAD_INF_CODES_BEGIN = '[violations]: get infraction codes begin',
  LOAD_INF_CODES_SUCCESS = '[violations]: get infraction codes success',
  LOAD_INF_CODES_ERROR = '[violations]: get infraction codes error',

  LOAD_HEARING_RESULTS_BEGIN = '[violations]: get hearing results begin',
  LOAD_HEARING_RESULTS_SUCCESS = '[violations]: get hearing results success',
  LOAD_HEARING_RESULTS_ERROR = '[violations]: get hearing results error',

  LOAD_CASE_STATUSES_BEGIN = '[violations]: get case statuses begin',
  LOAD_CASE_STATUSES_SUCCESS = '[violations]: get case statuses success',
  LOAD_CASE_STATUSES_ERROR = '[violations]: get case statuses error',

  LOAD_ACTIVITY_BEGIN = '[violations]: get activity begin',
  LOAD_ACTIVITY_SUCCESS = '[violations]: get activity success',
  LOAD_ACTIVITY_ERROR = '[violations]: get activity error',

  SEND_REOPEN_REQUEST_BEGIN = '[violations]: send reopen request begin',
  SEND_REOPEN_REQUEST_SUCCESS = '[violations]: send reopen request success',
  SEND_REOPEN_REQUEST_ERROR = '[violations]: send reopen request error',

  SEND_ONLINE_HEARING_BEGIN = '[violations]: send online hearing begin',
  SEND_ONLINE_HEARING_SUCCESS = '[violations]: send online hearing success',
  SEND_ONLINE_HEARING_ERROR = '[violations]: send online hearing error',
}

type LoadingAction = ReduxAction<ActionType.LOADING, boolean>

type ErrorAction = ReduxAction<ActionType.ERROR, ErrorType>

type ResetAction = ReduxAction<ActionType.RESET>

type LoadBeginAction = ReduxAction<ActionType.LOAD_BEGIN>

type LoadSuccessAction = ReduxAction<
  ActionType.LOAD_SUCCESS,
  {
    violations: Violations
    params: ViolationsParams
  }
>

type LoadErrorAction = ReduxAction<ActionType.LOAD_ERROR, ErrorType>

type LoadDetailsBeginAction = ReduxAction<ActionType.LOAD_DETAILS_BEGIN>

type LoadDetailsSuccessAction = ReduxAction<
  ActionType.LOAD_DETAILS_SUCCESS,
  ViolationDetails
>

type LoadDetailsErrorAction = ReduxAction<
  ActionType.LOAD_DETAILS_ERROR,
  ErrorType
>

type UpdateBeginAction = ReduxAction<ActionType.UPDATE_BEGIN>

type UpdateSuccessAction = ReduxAction<
  ActionType.UPDATE_SUCCESS,
  ViolationDetails
>

type UpdateErrorAction = ReduxAction<ActionType.UPDATE_ERROR, ErrorType>

type LoadInfCodesBeginAction = ReduxAction<ActionType.LOAD_INF_CODES_BEGIN>

type LoadInfCodesSuccessAction = ReduxAction<
  ActionType.LOAD_INF_CODES_SUCCESS,
  { infCodes: string[]; params: ViolationInfractionCodesParams }
>

type LoadInfCodesErrorAction = ReduxAction<
  ActionType.LOAD_INF_CODES_ERROR,
  ErrorType
>

type LoadHearingResultsBeginAction = ReduxAction<
  ActionType.LOAD_HEARING_RESULTS_BEGIN
>

type LoadHearingResultsSuccessAction = ReduxAction<
  ActionType.LOAD_HEARING_RESULTS_SUCCESS,
  HearingStatusType[]
>

type LoadHearingResultsErrorAction = ReduxAction<
  ActionType.LOAD_HEARING_RESULTS_ERROR,
  ErrorType
>

type LoadCaseStatusesBeginAction = ReduxAction<
  ActionType.LOAD_CASE_STATUSES_BEGIN
>

type LoadCaseStatusesSuccessAction = ReduxAction<
  ActionType.LOAD_CASE_STATUSES_SUCCESS,
  CaseStatusTypeKey[]
>

type LoadCaseStatusesErrorAction = ReduxAction<
  ActionType.LOAD_CASE_STATUSES_ERROR,
  ErrorType
>

type LoadActivityBeginAction = ReduxAction<ActionType.LOAD_ACTIVITY_BEGIN>

type LoadActivitySuccessAction = ReduxAction<
  ActionType.LOAD_ACTIVITY_SUCCESS,
  ViolationActivity[]
>

type LoadActivityErrorAction = ReduxAction<
  ActionType.LOAD_ACTIVITY_ERROR,
  ErrorType
>

type SendOnlineHearingBeginAction = ReduxAction<
  ActionType.SEND_ONLINE_HEARING_BEGIN
>

type SendOnlineHearingSuccessAction = ReduxAction<
  ActionType.SEND_ONLINE_HEARING_SUCCESS
>

type SendOnlineHearingErrorAction = ReduxAction<
  ActionType.SEND_ONLINE_HEARING_ERROR,
  ErrorType
>

type SendReopenRequestBeginAction = ReduxAction<
  ActionType.SEND_REOPEN_REQUEST_BEGIN
>

type SendReopenRequestSuccessAction = ReduxAction<
  ActionType.SEND_REOPEN_REQUEST_SUCCESS
>

type SendReopenRequestErrorAction = ReduxAction<
  ActionType.SEND_REOPEN_REQUEST_ERROR,
  ErrorType
>

export type Action =
  | LoadingAction
  | ErrorAction
  | ResetAction
  | LoadBeginAction
  | LoadSuccessAction
  | LoadErrorAction
  | LoadDetailsBeginAction
  | LoadDetailsSuccessAction
  | LoadDetailsErrorAction
  | UpdateBeginAction
  | UpdateSuccessAction
  | UpdateErrorAction
  | LoadInfCodesBeginAction
  | LoadInfCodesSuccessAction
  | LoadInfCodesErrorAction
  | LoadHearingResultsBeginAction
  | LoadHearingResultsSuccessAction
  | LoadHearingResultsErrorAction
  | LoadCaseStatusesBeginAction
  | LoadCaseStatusesSuccessAction
  | LoadCaseStatusesErrorAction
  | LoadActivityBeginAction
  | LoadActivitySuccessAction
  | LoadActivityErrorAction
  | SendOnlineHearingBeginAction
  | SendOnlineHearingSuccessAction
  | SendOnlineHearingErrorAction
  | SendReopenRequestBeginAction
  | SendReopenRequestSuccessAction
  | SendReopenRequestErrorAction

export default ActionType
