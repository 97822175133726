import React, { useMemo } from 'react'
import { ConnectedProps, connect } from 'react-redux'

import ViolationBaseInfo from '@common/ViolationBaseInfo'

const mapStateToProps = (state: ReduxState) => ({
  violation: state.violations.violation,
})

const connector = connect(mapStateToProps)

interface NativeProps {
  className?: string
}

type Props = NativeProps & ConnectedProps<typeof connector>

const BaseInfo: React.FC<Props> = ({ violation, className }) => {
  const content = useMemo(
    () =>
      violation ? (
        <ViolationBaseInfo
          violation={violation}
          parts={{ location: true, infoTable: true }}
          className={className}
        />
      ) : null,
    [violation, className]
  )
  return content
}

export default connector(BaseInfo) as React.FC<NativeProps>
