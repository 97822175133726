import React, { useCallback, useMemo, useState } from 'react'
import clsx from 'clsx'
import { ReactSVG } from 'react-svg'

import Header from '@common/Header'
import Input from '@components/Input'
import PaymentHeadRow from './PaymentHeadRow'
import PaymentRow from './PaymentRow'
import PagingFooter from '@common/PagingFooter'
import Filters from './Filters'

import { PaymentSortType } from '@typings/enums'
import { paymentsAvailablePerPage } from '@config'

import SearchIcon from '@assets/search.svg'

import styles from './styles.module.scss'

interface Props {
  payments: Payment[]
  isLoading: boolean
  page: number
  totalPages: number
  perPage: number
  load: (params: PaymentsApiLoadOptions) => void
  sort: (type: PaymentSortType | undefined) => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const View: React.FC<Props> = ({
  payments,
  isLoading,
  page,
  totalPages,
  perPage,
  load,
  sort,
  onSearchChange,
}) => {
  const tableBody = useMemo(
    () =>
      payments.map((payment) => (
        <PaymentRow key={payment.id} payment={payment} />
      )),
    [payments]
  )

  const onIdClick = useCallback(() => sort(PaymentSortType.id), [sort])
  const onNameClick = useCallback(() => sort(PaymentSortType.userName), [sort])
  const onAmountClick = useCallback(() => sort(PaymentSortType.amount), [sort])
  const onStatusClick = useCallback(() => sort(PaymentSortType.status), [sort])

  const [filtersOpen, setFiltersOpen] = useState(false)

  const openFilters = () => setFiltersOpen(true)
  const closeFilters = () => setFiltersOpen(false)

  return (
    <div className={clsx('page', styles['payments'])}>
      <Header />
      <div className={clsx('page-content', styles['page-content'])}>
        <div className={styles['heading']}>
          <h1>{'Payments'}</h1>
          <nav className={styles['navigation']}>
            <button className="primary-button" onClick={openFilters}>
              {'Filter'}
            </button>
          </nav>
        </div>
        <div className={styles['search-wrapper']}>
          <ReactSVG
            src={SearchIcon}
            className={clsx('svg', styles['search-icon'])}
          />
          <Input
            type="text"
            name="search"
            onChange={onSearchChange}
            inputContainerClassName={styles['search']}
            placeholder="search"
            containerClassName={styles['search-container']}
            disableError={true}
          />
        </div>
        <div className={styles['table-container']}>
          <table className={styles['table']}>
            <thead className={styles['table-head']}>
              <PaymentHeadRow
                onIdClick={onIdClick}
                onNameClick={onNameClick}
                onAmountClick={onAmountClick}
                onStatusClick={onStatusClick}
              />
            </thead>
            <tbody className={styles['table-body']}>{tableBody}</tbody>
          </table>
          <PagingFooter
            availablePerPage={paymentsAvailablePerPage}
            isLoading={isLoading}
            page={page}
            perPage={perPage}
            totalPages={totalPages}
            onChange={load}
          />
        </div>
      </div>
      <Filters open={filtersOpen} close={closeFilters} />
    </div>
  )
}

export default View
