import React, { useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { Mask } from 'text-mask-core'

import { mergeRefs } from '@services'

import InputBaseProps from '@components/Input/BaseProps'
import { createUniqueId } from '@services/dom'
import useStateProp from '@hooks/useStateProp'

import styles from './styles.module.scss'

export type Props = InputBaseProps<
  ExcludeInputType<'checkbox'>,
  {
    label?: React.ReactNode
    labelClassName?: string
    labelAsterisk?: boolean
    error?: React.ReactNode
    errorClassName?: string
    disableError?: boolean
    containerClassName?: string
    containerRef?: React.Ref<HTMLDivElement>
    inputContainerClassName?: string
    inputContainerRef?: React.Ref<HTMLDivElement>

    left?: React.ReactNode
    right?: React.ReactNode

    mask?: Mask
  }
>

const DefaultInput = (
  {
    label,
    labelClassName,
    labelAsterisk,
    error,
    errorClassName,
    disableError,
    containerClassName,
    containerRef,
    inputContainerClassName,
    inputContainerRef,
    left,
    right,
    className,
    ...props
  }: Props,
  ref: React.Ref<HTMLInputElement>
) => {
  const [id, setId] = useStateProp(props.id)
  const localRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    const onFocus = () => setIsFocused(true)
    const onBlur = () => setIsFocused(false)
    localRef.current?.addEventListener('focus', onFocus)
    localRef.current?.addEventListener('blur', onBlur)

    return () => {
      localRef.current?.removeEventListener('focus', onFocus)
      localRef.current?.removeEventListener('blur', onBlur)
    }
  }, [localRef.current])

  useEffect(() => {
    if (!id) {
      setId(createUniqueId())
    }
  }, [id])

  const errorNode = useMemo(() => {
    if (disableError) {
      return null
    }
    if (error === null) {
      error = undefined
    }

    const errorType = typeof error
    switch (errorType) {
      case 'string':
      case 'number': {
        return <p className={clsx('error-text', errorClassName)}>{error}</p>
      }
      case 'undefined':
      case 'boolean': {
        return <div className="error-placeholder" />
      }
      default: {
        return error
      }
    }
  }, [error, errorClassName, disableError])

  const labelNode = useMemo(
    () =>
      label && (
        <label htmlFor={id} className={clsx('input-label', labelClassName)}>
          {label}
          {labelAsterisk ? <span className="asterisk" /> : null}
        </label>
      ),
    [label, labelClassName, labelAsterisk, id]
  )

  const onContainerClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => event.currentTarget === event.target && localRef.current?.focus()

  return (
    <div
      className={clsx(
        'input-container',
        styles['input-wrapper'],
        containerClassName
      )}
      ref={containerRef}
    >
      {labelNode}
      <div
        className={clsx(
          styles['input-container'],
          isFocused && styles['focus'],
          props.disabled && styles['disabled'],
          error && styles['error'],
          inputContainerClassName
        )}
        ref={inputContainerRef}
        onClick={onContainerClick}
      >
        {left}
        <input
          {...props}
          id={id}
          ref={mergeRefs(ref, localRef)}
          className={clsx(
            'input',
            styles['input'],
            className,
            error && 'error'
          )}
        />
        {right}
      </div>
      {errorNode}
    </div>
  )
}

export default React.forwardRef(DefaultInput)
