import React, { useMemo, useRef, useState } from 'react'
import clsx from 'clsx'

import Collapsable from '@components/Collapsable'

import Arrow from '@assets/arrow.svg'

import styles from './styles.module.scss'
import { ReactSVG } from 'react-svg'
import ViolationHeadRow from '../ViolationHeadRow'
import { ViolationGroupType, ViolationSortType } from '@typings/enums'
import ViolationRow from '../ViolationRow'
import useStateProp from '@hooks/useStateProp'
import { getSort, getSortInfo } from '@services/violation'

interface Props {
  title: React.ReactNode
  violations: Violation[]
  className?: string
  type: ViolationType
  initialIsOpen?: boolean
}

const BuildingGroup: React.FC<Props> = ({
  title,
  violations: violationsProp,
  className,
  type,
  initialIsOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen)
  const [violations, setViolations] = useStateProp(violationsProp)
  const sortInfo = useRef<SortInfo<ViolationSortType>>({
    type: undefined,
  })

  const sort = (type: ViolationSortType) => {
    const info = getSortInfo(type)(sortInfo.current)
    sortInfo.current = info
    const sortFunc = getSort(info.type, info.reversed)
    setViolations((prev) => [...prev].sort(sortFunc))
  }

  const toggleIsOpen = () => setIsOpen((prev) => !prev)

  const content = useMemo(() => {
    const tableBody = violations.map((violation) => (
      <ViolationRow
        key={violation.id}
        groupBy={ViolationGroupType.buildingAddress}
        violation={violation}
      />
    ))

    return (
      <table className={styles['table']}>
        <thead className={styles['table-head']}>
          <ViolationHeadRow
            type={type}
            groupBy={ViolationGroupType.buildingAddress}
            onHearingDateClick={() => {
              sort(ViolationSortType.hearingDate)
            }}
            onBalanceClick={() => {
              sort(ViolationSortType.balance)
            }}
            onStatusClick={() => {
              sort(ViolationSortType.status)
            }}
          />
        </thead>
        <tbody className={styles['table-body']}>{tableBody}</tbody>
      </table>
    )
  }, [type, violations])

  return (
    <div
      className={clsx(
        styles['group'],
        isOpen && styles['group-open'],
        className
      )}
    >
      <button onClick={toggleIsOpen} className={styles['group-button']}>
        <ReactSVG src={Arrow} className={clsx('svg', styles['arrow-icon'])} />
        {title}
      </button>
      <Collapsable
        open={isOpen}
        vertical={true}
        className={styles['group-content']}
      >
        {content}
      </Collapsable>
    </div>
  )
}

export default BuildingGroup
