import { requiredValidate, validate } from '@services/validation'
import { FormikErrors } from 'formik'

interface FormData {
  files: File[]
  affirmation: boolean
  certification: boolean
}

export const formInitial: FormData = {
  files: [],
  affirmation: true,
  certification: true,
}

export const validateForm = ({
  affirmation,
  certification,
}: FormData): FormikErrors<FormData> => ({
  affirmation: validate(affirmation, requiredValidate),
  certification: validate(certification, requiredValidate),
})

export const formKeys: (keyof FormData)[] = [
  'files',
  'affirmation',
  'certification',
]

export default FormData
