const colors = {
  black: '#000000',
  paua: '#0e0065',
  prussianBlue: '#001f4f',
  persianBlue: '#4560ed',
  royalBlue: '#4e68ee',
  cornBlue: '#5c74ef',
  portage: '#8b9cf4',
  portageLight: '#8e9ef3',
  tropicalBlue: '#c1caf8',
  mineShaft: '#3d3d3d',
  codGray: '#1c1c1c',
  stormGray: '#6b738a',
  mystic: '#dee2ed',
  cadetBlue: '#a7aebf',
  aluminium: '#a8acb8',
  ghost: '#caccd4',
  fern: '#56bb65',
  mantis: '#78c648',
  emerald: '#4ac75d',
  carnation: '#fd5757',
  brilliantRose: '#ea5bb1',
  ronchi: '#eec14e',
  jaffa: '#f2994a',
  athensGray: '#e9eaf1',
  zircon: '#fbfcff',
  selagoDarker: '#eaedfd',
  selagoDark: '#edf0fd',
  selago: '#f8f9fe',
  selagoLight: '#f2f4fd',
  white: '#ffffff',
}

export default colors
