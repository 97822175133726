import React, { useMemo } from 'react'
import clsx from 'clsx'
import { ReactSVG } from 'react-svg'

import { PaidStatusType, PaidStatusTypeLabel } from '@typings/enums'

import CloseIcon from '@assets/close.svg'
import SkipIcon from '@assets/skip.svg'

import styles from './styles.module.scss'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  type: PaidStatusType
  children?: string
}

const Status: React.FC<Props> = ({
  type,
  children = PaidStatusTypeLabel[type],
  className,
  ...props
}) => {
  const icon = useMemo(() => {
    switch (type) {
      case PaidStatusType.unpaid:
      case PaidStatusType.unpaidFee: {
        return CloseIcon
      }
      case PaidStatusType.paid: {
        return SkipIcon
      }
      default: {
        return ''
      }
    }
  }, [type])

  return (
    <div className={clsx(styles['status'], styles[type], className)} {...props}>
      <ReactSVG src={icon} className={clsx('svg', styles['icon'])} />
      <p className={styles['status-text']}>{children}</p>
    </div>
  )
}

export default Status
