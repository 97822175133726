import React, { useMemo } from 'react'
import clsx from 'clsx'

import InputBaseProps from '@components/Input/BaseProps'

import styles from './styles.module.scss'

export type Props = InputBaseProps<
  'checkbox' | 'radio',
  {
    containerClassName?: string
    inputContainerClassName?: string
    checkmark?: React.ReactNode
    checkmarkClassName?: string
    checkmarkContainerClassName?: string
    label?: React.ReactNode
    labelClassName?: string

    error?: React.ReactNode
  }
>

const Checkbox = (
  {
    containerClassName,
    inputContainerClassName,
    className,
    checkmark,
    checkmarkClassName,
    checkmarkContainerClassName,
    label = 'Remember Me',
    labelClassName,
    error,
    ...props
  }: Props,
  ref: React.Ref<HTMLInputElement>
) => {
  const checkmarkNode = useMemo(() => {
    checkmark = checkmark || (
      <div className={clsx(styles['checkmark'], checkmarkClassName)} />
    )

    return (
      <div
        className={clsx(
          styles['checkmark-container'],
          !!error && styles['error'],
          props.type === 'radio' && styles['radio-checkmark-container'],
          checkmarkContainerClassName
        )}
      >
        {checkmark}
      </div>
    )
  }, [checkmark, checkmarkClassName, checkmarkContainerClassName, error])

  const labelNode = useMemo(
    () => <p className={clsx(styles['label'], labelClassName)}>{label}</p>,

    [label, labelClassName]
  )

  return (
    <label className={clsx(styles['container'], containerClassName)}>
      <div className={clsx(styles['input-container'], inputContainerClassName)}>
        <input
          {...props}
          type={props.type}
          className={clsx(styles['input'], className)}
          ref={ref}
        />
        {checkmarkNode}
      </div>
      {labelNode}
    </label>
  )
}

export default React.forwardRef(Checkbox)
