import { isNullable } from '.'

type FirstValue<T> = T | T[]

function first<T>(value: FirstValue<T>): T | undefined
function first<T>(value: FirstValue<T>, def: T): T

function first<T>(value: T | T[], def?: T) {
  return !Array.isArray(value) ? value : value.length > 0 ? value[0] : def
}

type ToArrayValue<T> = T | T[] | undefined | null

function toArray<T>(value: ToArrayValue<T> | undefined | null): T[]
function toArray<T>(
  value: ToArrayValue<T>,
  allowNullable: true
): (T | undefined | null)[]
function toArray<T>(value: ToArrayValue<T>, allowNullable: false): T[]

function toArray<T>(value: T | T[], allowNullable = false) {
  return Array.isArray(value)
    ? value
    : !isNullable(value) || allowNullable
    ? [value]
    : []
}

export { first, toArray }

export default {
  first,
  toArray,
}
