enum BalanceType {
  fee = 'fee',
  fine = 'fine',
}

enum BalanceLabel {
  fee = 'Fee',
  fine = 'Fine',
}

const BalanceTypeLabel = {
  [BalanceType.fee]: BalanceLabel.fee,
  [BalanceType.fine]: BalanceLabel.fine,
}

export { BalanceTypeLabel, BalanceLabel }

export default BalanceType
