import React from 'react'
import clsx from 'clsx'

import Link from '@components/Link'

import { formatAmount } from '@services/money'
import links from '@routes/links'

import styles from './styles.module.scss'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > {
  user: User
}

const UserRow: React.FC<Props> = ({ user, className, ...props }) => {
  return (
    <tr {...props} className={clsx('tr', styles['row'], className)}>
      <td>
        <Link
          className={clsx('blue-link', styles['id'])}
          to={links.userDetails(user.id)}
        >
          {user.id}
        </Link>
      </td>
      <td>
        {user.signature_url ? (
          <Link
            className={clsx('blue-link', styles['id'])}
            to={user.signature_url}
          >
            {`${user.firstName} ${user.lastName}`}
          </Link>
        ) : (
          <p
            className={styles['name']}
          >{`${user.firstName} ${user.lastName}`}</p>
        )}
      </td>
      <td>
        <a
          className={clsx('blue-link', styles['email'])}
          href={`mailto:${user.email}`}
        >
          {user.email}
        </a>
      </td>
      <td>
        <a className={styles['phone-number']} href={`tel:${user.phone || ''}`}>
          {user.phone || ''}
        </a>
      </td>
      <td>
        <p className={styles['fo-buildings']}>{user.buildings}</p>
      </td>
      <td>
        <p className={styles['hired-cases']}>
          {`${user.hiredCases} / ${user.allCases}`}
        </p>
      </td>
      <td>
        <p className={styles['sanisaver-income']}>
          {formatAmount(user.income)}
        </p>
      </td>
    </tr>
  )
}

export default UserRow
