enum HearingStatusType {
  inViolation = 'IN VIOLATION',
  dismissed = 'DISMISSED',
  defaulted = 'DEFAULTED',
  assigned = 'ASSIGNED',
  isSubmitted = 'IS SUBMITTED',
  newIssuance = 'NEW ISSUANCE',
  hearingCompleted = 'HEARING COMPLETED',
  writtenOff = 'WRITTEN OFF',
  adjourned = 'ADJOURNED',
  paidInFull = 'PAID IN FULL',
}

const HearingStatusLabel = {
  [HearingStatusType.inViolation]: 'in violation',
  [HearingStatusType.dismissed]: 'dismissed',
  [HearingStatusType.defaulted]: 'defaulted',
  [HearingStatusType.assigned]: 'assigned',
  [HearingStatusType.isSubmitted]: 'is submitted',
  [HearingStatusType.newIssuance]: 'new issuance',
  [HearingStatusType.hearingCompleted]: 'hearing completed',
  [HearingStatusType.writtenOff]: 'written off',
  [HearingStatusType.adjourned]: 'adjourned',
  [HearingStatusType.paidInFull]: 'paid in full',
}

const HearingStatusClassName = {
  [HearingStatusType.inViolation]: 'in-violation',
  [HearingStatusType.dismissed]: 'dismissed',
  [HearingStatusType.defaulted]: 'defaulted',
  [HearingStatusType.assigned]: 'assigned',
  [HearingStatusType.isSubmitted]: 'is-submitted',
  [HearingStatusType.newIssuance]: 'new-issuance',
  [HearingStatusType.hearingCompleted]: 'hearing-completed',
  [HearingStatusType.writtenOff]: 'written-off',
  [HearingStatusType.adjourned]: 'adjourned',
  [HearingStatusType.paidInFull]: 'paid-in-full',
}

const hearingStatusTypes = [
  HearingStatusType.inViolation,
  HearingStatusType.dismissed,
  HearingStatusType.defaulted,
  HearingStatusType.assigned,
  HearingStatusType.isSubmitted,
  HearingStatusType.newIssuance,
  HearingStatusType.hearingCompleted,
  HearingStatusType.writtenOff,
  HearingStatusType.adjourned,
  HearingStatusType.paidInFull,
]

export { HearingStatusLabel, HearingStatusClassName, hearingStatusTypes }

export default HearingStatusType
