import React, { useCallback, useEffect, useMemo } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ReactSVG } from 'react-svg'
import clsx from 'clsx'

import Input from '@components/Input'
import PagingFooter from '@common/PagingFooter'
import UserHeadRow from './UserHeadRow'
import UserRow from './UserRow'

import { emptyFilter, emptySort } from '@services/user'
import { defaultPerPage, usersAvailablePerPage } from '@config'
import { UsersSortType } from '@typings/enums'
import usersActions from '@redux/users/actions'

import SearchIcon from '@assets/search.svg'

import styles from '../styles.module.scss'

const mapStateToProps = (state: ReduxState) => ({
  users: state.users.users,
  isLoading: state.users.isLoading,
  page: state.users.params.page,
  perPage: state.users.params.per_page,
  totalPages: state.users.params.totalPages,
})

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  usersActions: bindActionCreators<
    typeof usersActions,
    BindedAsyncActions<typeof usersActions>
  >(usersActions, dispatch),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type Props = ConnectedProps<typeof connector>

const TableSection: React.FC<Props> = ({
  users = [],
  isLoading,
  page,
  totalPages,
  perPage,
  usersActions: { load: loadUsers, sort, filter },
}) => {
  useEffect(() => {
    loadUsers(
      { page: 1, per_page: defaultPerPage, ...emptyFilter, ...emptySort },
      true
    )
  }, [loadUsers])

  const load = useCallback(
    (params: UsersApiLoadOptions) => loadUsers(params, false),
    [loadUsers]
  )

  const tableBody = useMemo(
    () => users.map((user) => <UserRow key={user.id} user={user} />),
    [users]
  )

  const onSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      filter({ search: value || undefined })
    },
    [filter]
  )

  return (
    <section className={styles['table-section']}>
      <div className={styles['heading']}>
        <h1>{'Users'}</h1>
      </div>
      <div className={styles['search-wrapper']}>
        <ReactSVG
          src={SearchIcon}
          className={clsx('svg', styles['search-icon'])}
        />
        <Input
          type="text"
          name="search"
          inputContainerClassName={styles['search']}
          placeholder="search"
          containerClassName={styles['search-container']}
          disableError={true}
          onChange={onSearchChange}
        />
      </div>
      <div className={styles['table-container']}>
        <table className={styles['table']}>
          <thead className={styles['table-head']}>
            <UserHeadRow
              onIDClick={() => sort({ order_by: UsersSortType.id })}
              onNameClick={() => sort({ order_by: UsersSortType.name })}
              onFOBuildingsClick={() =>
                sort({ order_by: UsersSortType.foBuildings })
              }
              onHiredCasesClick={() =>
                sort({ order_by: UsersSortType.hiredCases })
              }
              onSanisaverIncomeClick={() =>
                sort({ order_by: UsersSortType.saniIncome })
              }
            />
          </thead>
          <tbody className={styles['table-body']}>{tableBody}</tbody>
        </table>
        <PagingFooter
          availablePerPage={usersAvailablePerPage}
          isLoading={isLoading}
          page={page || 0}
          perPage={perPage || 0}
          totalPages={totalPages || 0}
          onChange={load}
        />
      </div>
    </section>
  )
}

export default connector(TableSection) as React.FC
