import React from 'react'
import styles from '../styles.module.scss'
import Heading from '@common/Heading'
import ScrollContainer from 'react-indiana-drag-scroll'

interface Props {
  infractionCode: InfractionCode
  goBack: () => void
}

const gradientSelectors = ['green-gradient', 'blue-gradient', 'red-gradient']

const Head: React.FC<Props> = ({ infractionCode, goBack }) => {
  return (
    <section className={styles['defence-head']}>
      {infractionCode && (
        <ScrollContainer vertical={false} className="scroll-container">
          <div className={styles['defence-head-container']}>
            <div>
              <Heading goBack={goBack} className={styles['title-container']}>
                {infractionCode.code}
              </Heading>
              <div className={styles['defence-subtitle']}>
                {infractionCode.description}
              </div>
            </div>
            <div className={styles['defence-section-of-law']}>
              <div className={styles['defence-subtitle']}>Section of law</div>
              <div>{infractionCode && infractionCode.sectionOfLaw}</div>
            </div>
            {infractionCode.allOffenses.map((offense, index) => (
              <>
                <div className={styles[gradientSelectors[index]]} />
                <div className={styles['defence-offence-block']}>
                  <div className={styles['defence-subtitle']}>Offence</div>
                  <div>{offense.offense}</div>
                </div>
                <div className={styles['defence-offence-block']}>
                  <div className={styles['defence-subtitle']}>Penalty</div>
                  <div>{offense.penalty}</div>
                </div>
                <div className={styles['defence-offence-block']}>
                  <div className={styles['defence-subtitle']}>Default</div>
                  <div>{offense.default}</div>
                </div>
              </>
            ))}
          </div>
        </ScrollContainer>
      )}
    </section>
  )
}

export default Head
