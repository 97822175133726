import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import View from './view'

type Props = RouteComponentProps<{ id: string }>

const UserDetails: React.FC<Props> = ({
  match: {
    params: { id },
  },
  history: { replace, goBack },
}) => {
  return <View goBack={goBack} replace={replace} id={id} />
}

export default UserDetails
