import React, { useMemo } from 'react'
import clsx from 'clsx'

import Bar from './Bar'

import useRem from '@hooks/useRem'
import { minBarHeight } from './constants'
import { toRem } from '@services/dom'
import useStateRef from '@hooks/useStateRef'

import styles from './styles.module.scss'

type KeyExtractor<I = unknown> = MapFunc<I, React.Key>
type ValueExtractor<I = unknown> = MapFunc<I, number>
type LabelExtractor<I = unknown> = MapFunc<I, React.ReactNode>
type LabelItemPropsExtractor<I = unknown> = MapFunc<
  I,
  React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>
>
type BarItemPropsExtractor<I = unknown> = MapFunc<
  I,
  React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>
>
type BarPropsExtractor<I = unknown> = MapFunc<
  I,
  React.ComponentProps<typeof Bar>
>

const defaultKeyExtractor: KeyExtractor = (_, index) => index
const defaultValueExtractor: ValueExtractor = (item) =>
  typeof item === 'number' ? item : 0
const defaultLabelExtractor: LabelExtractor = (item, index) =>
  typeof item === 'string' || typeof item === 'number' ? item : index

interface Props<I> {
  data: I[]
  keyExtractor?: KeyExtractor<I>
  valueExtractor?: ValueExtractor<I>
  labelExtractor?: LabelExtractor<I>
  labelItemPropsExtractor?: LabelItemPropsExtractor<I>
  barItemPropsExtractor?: BarItemPropsExtractor<I>
  barPropsExtractor?: BarPropsExtractor<I>

  className?: string
  max?: number
}

const BarChart = <I extends unknown>({
  data,
  keyExtractor = defaultKeyExtractor,
  labelExtractor = defaultLabelExtractor,
  valueExtractor = defaultValueExtractor,
  labelItemPropsExtractor,
  barItemPropsExtractor,
  barPropsExtractor,
  className,
  max,
}: Props<I>) => {
  const barsRef = useStateRef<HTMLUListElement>(null)

  const rem = useRem()

  const bars = useMemo(() => {
    const maxValue = Math.max(max || 0, ...data.map(valueExtractor))

    const containerHeight = barsRef.current && barsRef.current?.scrollHeight

    const barsMaxHeight = containerHeight && containerHeight - rem(minBarHeight)

    const height =
      barsMaxHeight && barsMaxHeight > 0
        ? (value: number) => (value * barsMaxHeight) / maxValue
        : undefined

    return data.map((...args) => {
      const itemProps = barItemPropsExtractor?.(...args)
      return (
        <li
          key={keyExtractor(...args)}
          {...itemProps}
          style={{
            ...itemProps?.style,
            height: height
              ? toRem(height(valueExtractor(...args)) + rem(minBarHeight))
              : itemProps?.style?.height,
          }}
          className={clsx(styles['bar-item'], itemProps?.className)}
        >
          <Bar {...barPropsExtractor?.(...args)} />
        </li>
      )
    })
  }, [
    data,
    barsRef.current,
    max,
    rem,
    valueExtractor,
    barItemPropsExtractor,
    barPropsExtractor,
  ])

  const labels = useMemo(
    () =>
      data.map((...args) => {
        const itemProps = labelItemPropsExtractor?.(...args)

        return (
          <li
            key={keyExtractor(...args)}
            {...itemProps}
            className={clsx(styles['label-item'], itemProps?.className)}
          >
            {labelExtractor(...args)}
          </li>
        )
      }),
    [data]
  )

  return (
    <div className={clsx(styles['chart'], className)}>
      <ul className={styles['bars']} ref={barsRef}>
        {bars}
      </ul>
      <ul className={styles['labels']}>{labels}</ul>
    </div>
  )
}

export default BarChart
