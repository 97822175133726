import React from 'react'
import clsx from 'clsx'

import Link from '@components/Link'

import links from '@routes/links'

import Form, { FormData } from './form'

import styles from '../../styles.module.scss'

interface Props {
  isLoading: boolean
  onSubmit: (values: FormData) => void
}

const FormCard: React.FC<Props> = ({ isLoading, onSubmit }) => (
  <div
    className={clsx(
      'card',
      'big-card',
      styles['forgot-password-card'],
      styles['forgot-password-form-card']
    )}
  >
    <h1 className={clsx('black-text', styles['title'])}>
      {'Forgot Password?'}
    </h1>
    <p className={clsx('additional-text', styles['subtitle'])}>
      {'We will send a reset link to your email'}
    </p>
    <Form isLoading={isLoading} onSubmit={onSubmit} />
    <Link to={links.login()} className={clsx('link', styles['login-link'])}>
      {'Back to Login'}
    </Link>
  </div>
)

export default FormCard
