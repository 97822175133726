import React from 'react'
import { ReactSVG } from 'react-svg'
import clsx from 'clsx'

import NextArrowIcon from '@assets/next-arrow.svg'

import styles from './styles.module.scss'

interface Props {
  children: React.ReactNode
  goBack?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  className?: string
  hClassName?: string
}

const Heading: React.FC<Props> = ({
  children,
  goBack = undefined,
  className,
  hClassName,
}) => {
  return (
    <div className={clsx(styles['title-container'], className)}>
      <button
        onClick={goBack}
        className={clsx(
          styles['back-button'],
          !goBack && styles['back-button-hidden']
        )}
      >
        <ReactSVG
          src={NextArrowIcon}
          className={clsx('svg', styles['back-icon'])}
        />
      </button>
      <h1 className={clsx(styles['title'], hClassName)}>{children}</h1>
    </div>
  )
}

export default Heading
