import React from 'react'
import styles from '../../styles.module.scss'
import { ReactSVG } from 'react-svg'
import DragHelper from '@assets/drag-helper.svg'
import clsx from 'clsx'

interface Props {
  question: Question
}

const QuestionItem: React.FC<Props> = ({ question }) => {
  return (
    <>
      <div className={styles['question-item-edit']}>
        <div className={styles['question-item-block']}>
          <div className={styles['header']}>
            <input
              required={true}
              placeholder="Enter your question"
              value={question.question}
            />
          </div>
          <div className={styles['answers']}>
            <div className={styles['answer-item']}>{question.answer}</div>
          </div>
        </div>
        <ReactSVG src={DragHelper} className={clsx('svg', styles['logo'])} />
      </div>
    </>
  )
}

export default QuestionItem
