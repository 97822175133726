import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import ViolationsTableWrapper from '../ViolationsTableWrapper'
import ViolationsWrapper from '../wrapper'

import { ViolationType } from '@typings/enums'

const ClosedViolations: React.FC<RouteComponentProps> = ViolationsWrapper(
  ViolationsTableWrapper<ClosedViolation>(ViolationType.closed)
)

export default ClosedViolations
