import { LinkProps } from 'react-router-dom'
import links from '@routes/links'

const violationsLinks: LinkProps[] = [
  {
    to: `${links.hiredViolations()}`,
    children: 'Hired',
  },
  {
    to: `${links.closedViolations()}`,
    children: 'Closed',
  },
  {
    to: `${links.attendedViolations()}`,
    children: 'Attended',
  },
  {
    to: `${links.unhiredViolations()}`,
    children: 'Unhired',
  },
]

export default violationsLinks
