import { useCallback, useLayoutEffect, useRef } from 'react'
import {
  MaskConfig,
  TextMaskInput,
  createTextMaskInputElement,
} from 'text-mask-core'

export interface Options extends Omit<MaskConfig, 'inputElement'> {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  value?: string | number
  input: React.RefObject<HTMLInputElement>
}

export type Change = (event: React.ChangeEvent<HTMLInputElement>) => void

export type Hook = (options: Options) => Change

const useMaskedInput: Hook = ({
  onChange,
  input,
  keepCharPositions,
  mask,
  pipe,
  placeholderChar,
  showMask,
  guide = false,
  value = input.current?.value || '',
}: Options) => {
  const textMask = useRef<TextMaskInput>()

  const init = useCallback(() => {
    if (!input.current) {
      return
    }

    textMask.current = createTextMaskInputElement({
      guide,
      inputElement: input.current,
      keepCharPositions,
      mask,
      pipe,
      placeholderChar,
      showMask,
    })

    textMask.current.update(value)
  }, [
    textMask,
    input.current,
    keepCharPositions,
    mask,
    pipe,
    placeholderChar,
    showMask,
  ])

  useLayoutEffect(init, [
    guide,
    keepCharPositions,
    mask,
    pipe,
    placeholderChar,
    showMask,
  ])

  useLayoutEffect(() => {
    if (value === input.current?.value) {
      return
    }

    textMask.current?.update(value)
  }, [value])

  return (event) => {
    textMask.current?.update()

    onChange?.(event)
  }
}

export default useMaskedInput
