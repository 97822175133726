import React from 'react'
import clsx from 'clsx'
import { useFormik } from 'formik'

import Input from '@components/Input'

import styles from '../../styles.module.scss'
import { emailValidate, validate } from '@services/validation'
import { getFormErrors } from '@services/error'

export interface FormData {
  email: string
}

interface Props {
  isLoading: boolean
  onSubmit: (values: FormData) => void
}

const Form: React.FC<Props> = ({ isLoading, onSubmit }) => {
  const formik = useFormik<FormData>({
    initialValues: {
      email: '',
    },
    onSubmit,
    validateOnBlur: true,
    validate: async ({ email }) =>
      getFormErrors({ email: validate(email, emailValidate) }),
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        name="email"
        id="email"
        type="email"
        placeholder="example@email.com"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        required={true}
        label="Email"
        containerClassName={styles['input-container']}
        error={formik.touched.email ? formik.errors.email : null}
      />
      <button
        type="submit"
        className={clsx('primary-button', styles['submit-btn'])}
        disabled={isLoading || !formik.isValid}
      >
        {'Send'}
      </button>
    </form>
  )
}

export default Form
