import { FormikErrors } from 'formik'
import { maxFileSize, requiredValidate, validate } from '@services/validation'
import { mB } from '@services/utils/file'

interface FormData {
  files: File[]
  certification: boolean
}

export const formInitial: FormData = {
  files: [],
  certification: true,
}

export const validateForm = ({
  files,
  certification,
}: FormData): FormikErrors<FormData> => ({
  files: validate<File | File[]>(
    files,
    maxFileSize(mB(3), 'cannot exceed 3MB')
  ),
  certification: validate<boolean>(certification, requiredValidate),
})

export const formKeys: (keyof FormData)[] = ['files', 'certification']

export default FormData
