import React, { useCallback, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'

import paymentsActions from '@redux/payments/actions'
import { emptyFilter, emptySort } from '@services/payment'
import { defaultPerPage } from '@config'

import View from './view'

const mapStateToProps = (state: ReduxState) => ({
  payments: state.payments.payments,
  isLoading: state.payments.isLoading,
  page: state.payments.params.page,
  totalPages: state.payments.params.totalPages,
  perPage: state.payments.params.per_page,
})

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  paymentsActions: bindActionCreators<
    typeof paymentsActions,
    BindedAsyncActions<typeof paymentsActions>
  >(paymentsActions, dispatch),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type NativeProps = RouteComponentProps

type Props = NativeProps & ConnectedProps<typeof connector>

const Payments: React.FC<Props> = ({
  paymentsActions: {
    load: loadPayments,
    filter: filterPayments,
    sort: sortPayments,
  },
  payments = [],
  isLoading,
  page,
  totalPages,
  perPage,
}) => {
  useEffect(() => {
    loadPayments(
      { ...emptyFilter, ...emptySort, page: 1, per_page: defaultPerPage },
      true
    )
  }, [loadPayments])

  const load = useCallback(
    (params: PaymentsApiLoadOptions) => loadPayments(params, false),
    [loadPayments]
  )

  const sort = useCallback(
    (order_by: PaymentSortType | undefined) => {
      sortPayments({ order_by })
    },
    [sortPayments]
  )

  const onSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      filterPayments({
        search: value || '',
      })
    },
    [filterPayments]
  )

  return (
    <View
      payments={payments}
      isLoading={isLoading}
      page={page || 0}
      totalPages={totalPages || 0}
      perPage={perPage || 0}
      load={load}
      sort={sort}
      onSearchChange={onSearchChange}
    />
  )
}

export default connector(Payments) as React.FC<NativeProps>
