enum UserRole {
  superAdmin = 'super-admin',
  admin = 'admin',
  manager = 'manager',
  user = 'user',
}

const UserRoleLabel = {
  [UserRole.superAdmin]: 'Super Admin',
  [UserRole.admin]: 'Admin',
  [UserRole.manager]: 'Manager',
  [UserRole.user]: 'User',
}

enum UsersSortType {
  id = 'users.id',
  name = 'users.name',
  foBuildings = 'buildings_number',
  hiredCases = 'hired_cases',
  saniIncome = 'income',
}

const usersFilterTypes: UsersFilterType[] = ['role', 'search']

export { UserRole, UsersSortType, UserRoleLabel, usersFilterTypes }
